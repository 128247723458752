import myConfig from './myConfig';
import axios from 'axios';

const token = localStorage.getItem('token');
export default {
    getAll(callback){

        axios.get(myConfig.api_url+`/api/v1/allCheques?api_token=${token}`)
        .then((response) => {
            if (response.status == 200) {
                callback(response.data);
            }
            else{
                callback(null);
            }
        })
        .catch(() => {
            callback(null);
        });

    },

    getAllWithRester(callback){

        axios.get(myConfig.api_url+`/api/v1/getAllChequesWithRester?api_token=${token}`)
        .then((response) => {
            if (response.status == 200) {
                callback(response.data);
            }
            else{
                callback(null);
            }
        })
        .catch(() => {
            callback(null);
        });

    },

    add(args,callback){

        
        axios.post(myConfig.api_url+`/api/v1/addCheque?api_token=${token}`,args)
        .then((response) => {
            if (response.status == 200) {
                callback(response.data);
            }
            else{
                callback(null);
            }
        })
        .catch(() => {
            callback(null);
        });

    },

    Encaisser(args,callback){
        axios.post(myConfig.api_url+`/api/v1/Encaisser?api_token=${token}`,args)
        .then((response) => {
            if (response.status == 200) {
                callback(response.data);
            }
            else{
                callback(null);
            }
        })
        .catch(() => {
            callback(null);
        });

    },

    Encaisser2(args,callback){
        axios.post(myConfig.api_url+`/api/v1/Encaisser2?api_token=${token}`,args)
        .then((response) => {
            if (response.status == 200) {
                callback(response.data);
            }
            else{
                callback(null);
            }
        })
        .catch(() => {
            callback(null);
        });

    },

    RemmetreBanque(args,callback){
        axios.post(myConfig.api_url+`/api/v1/RemmetreBanque?api_token=${token}`,args)
        .then((response) => {
            if (response.status == 200) {
                callback(response.data);
            }
            else{
                callback(null);
            }
        })
        .catch(() => {
            callback(null);
        });

    },

    Impayer(args,callback){
        axios.post(myConfig.api_url+`/api/v1/Impayer?api_token=${token}`,args)
        .then((response) => {
            if (response.status == 200) {
                callback(response.data);
            }
            else{
                callback(null);
            }
        })
        .catch(() => {
            callback(null);
        });

    },

    Restituer(args,callback){
        axios.post(myConfig.api_url+`/api/v1/Restituer?api_token=${token}`,args)
        .then((response) => {
            if (response.status == 200) {
                callback(response.data);
            }
            else{
                callback(null);
            }
        })
        .catch(() => {
            callback(null);
        });

    },

    update(args,callback){
        axios.post(myConfig.api_url+`/api/v1/updateCheque?api_token=${token}`,args)
        .then((response) => {
            if (response.status == 200) {
                callback(response.data);
            }
            else{
                callback(null);
            }
        })
        .catch(() => {
            callback(null);
        });

    },

    delete(args,callback){
        axios.post(myConfig.api_url+`/api/v1/deleteCheque?api_token=${token}`,args)
        .then((response) => {
            if (response.status == 200) {
                callback(response.data);
            }
            else{
                callback(null);
            }
        })
        .catch(() => {
            callback(null);
        });

    },

}