import myConfig from './myConfig';
import axios from 'axios';

const token = localStorage.getItem('token');
export default {
    getAll(callback){

        axios.get(myConfig.api_url+`/api/v1/allGroupes?api_token=${token}`)
        .then((response) => {
            if (response.status == 200) {
                callback(response.data);
            }
            else{
                callback(null);
            }
        })
        .catch(() => {
            callback(null);
        });

    },

    getAll2(callback){

        axios.get(myConfig.api_url+`/api/v1/allGroupes2?api_token=${token}`)
        .then((response) => {
            if (response.status == 200) {
                callback(response.data);
            }
            else{
                callback(null);
            }
        })
        .catch(() => {
            callback(null);
        });

    },

    getGroupePresences(args,callback){

        axios.post(myConfig.api_url+`/api/v1/getGroupePresences?api_token=${token}`,args)
        .then((response) => {
            if (response.status == 200) {
                callback(response.data);
            }
            else{
                callback(null);
            }
        })
        .catch(() => {
            callback(null);
        });

    },

    getGroupeSeances(args,callback){

        axios.post(myConfig.api_url+`/api/v1/getGroupeSeances?api_token=${token}`,args)
        .then((response) => {
            if (response.status == 200) {
                callback(response.data);
            }
            else{
                callback(null);
            }
        })
        .catch(() => {
            callback(null);
        });

    },
    CreateSeance(args,callback){

        axios.post(myConfig.api_url+`/api/v1/CreateSeance?api_token=${token}`,args)
        .then((response) => {
            if (response.status == 200) {
                callback(response.data);
            }
            else{
                callback(null);
            }
        })
        .catch(() => {
            callback(null);
        });

    },
    UpdateSeance(args,callback){

        axios.post(myConfig.api_url+`/api/v1/UpdateSeance?api_token=${token}`,args)
        .then((response) => {
            if (response.status == 200) {
                callback(response.data);
            }
            else{
                callback(null);
            }
        })
        .catch(() => {
            callback(null);
        });

    },
    getGroupeInscrits(args,callback){

        axios.post(myConfig.api_url+`/api/v1/getGroupeInscrits?api_token=${token}`,args)
        .then((response) => {
            if (response.status == 200) {
                callback(response.data);
            }
            else{
                callback(null);
            }
        })
        .catch(() => {
            callback(null);
        });

    },
    getSeanceInvites(args,callback){

        axios.post(myConfig.api_url+`/api/v1/getSeanceInvites?api_token=${token}`,args)
        .then((response) => {
            if (response.status == 200) {
                callback(response.data);
            }
            else{
                callback(null);
            }
        })
        .catch(() => {
            callback(null);
        });

    },
    createInvites(args,callback){

        axios.post(myConfig.api_url+`/api/v1/createInvites?api_token=${token}`,args)
        .then((response) => {
            if (response.status == 200) {
                callback(response.data);
            }
            else{
                callback(null);
            }
        })
        .catch(() => {
            callback(null);
        });

    },
    deleteInvites(args,callback){

        axios.post(myConfig.api_url+`/api/v1/deleteInvites?api_token=${token}`,args)
        .then((response) => {
            if (response.status == 200) {
                callback(response.data);
            }
            else{
                callback(null);
            }
        })
        .catch(() => {
            callback(null);
        });

    },
    getAllGroupeInscrits(args,callback){

        axios.post(myConfig.api_url+`/api/v1/getAllGroupeInscrits?api_token=${token}`,args)
        .then((response) => {
            if (response.status == 200) {
                callback(response.data);
            }
            else{
                callback(null);
            }
        })
        .catch(() => {
            callback(null);
        });

    },
    getGroupeResterAPayer(args,callback){

        axios.post(myConfig.api_url+`/api/v1/getGroupeResterAPayer?api_token=${token}`,args)
        .then((response) => {
            if (response.status == 200) {
                callback(response.data);
            }
            else{
                callback(null);
            }
        })
        .catch(() => {
            callback(null);
        });

    },
    deleteSeance(args,callback){

        axios.post(myConfig.api_url+`/api/v1/deleteSeance?api_token=${token}`,args)
        .then((response) => {
            if (response.status == 200) {
                callback(response.data);
            }
            else{
                callback(null);
            }
        })
        .catch(() => {
            callback(null);
        });

    },
    getSeancePresences(args,callback){

        axios.post(myConfig.api_url+`/api/v1/getSeancePresences?api_token=${token}`,args)
        .then((response) => {
            if (response.status == 200) {
                callback(response.data);
            }
            else{
                callback(null);
            }
        })
        .catch(() => {
            callback(null);
        });

    },

    updatePresence(args,callback){

        axios.post(myConfig.api_url+`/api/v1/updatePresence?api_token=${token}`,args)
        .then((response) => {
            if (response.status == 200) {
                callback(response.data);
            }
            else{
                callback(null);
            }
        })
        .catch(() => {
            callback(null);
        });

    },

    getGroupeProfTarif(args,callback){

        axios.post(myConfig.api_url+`/api/v1/getGroupeProfTarif?api_token=${token}`,args)
        .then((response) => {
            if (response.status == 200) {
                callback(response.data);
            }
            else{
                callback(null);
            }
        })
        .catch(() => {
            callback(null);
        });

    },

    getGroupeCategoriesByIdGroupe(args,callback){

        axios.post(myConfig.api_url+`/api/v1/getGroupeCategoriesByIdGroupe?api_token=${token}`,args)
        .then((response) => {
            if (response.status == 200) {
                callback(response.data);
            }
            else{
                callback(null);
            }
        })
        .catch(() => {
            callback(null);
        });

    },

    getGroupeHorairesByIdGroupe(args,callback){

        axios.post(myConfig.api_url+`/api/v1/getGroupeHorairesByIdGroupe?api_token=${token}`,args)
        .then((response) => {
            if (response.status == 200) {
                callback(response.data);
            }
            else{
                callback(null);
            }
        })
        .catch(() => {
            callback(null);
        });

    },

    getGroupePeriodesByIdGroupe(args,callback){

        axios.post(myConfig.api_url+`/api/v1/getGroupePeriodesByIdGroupe?api_token=${token}`,args)
        .then((response) => {
            if (response.status == 200) {
                callback(response.data);
            }
            else{
                callback(null);
            }
        })
        .catch(() => {
            callback(null);
        });

    },

    getGroupesByMatCadAnn(args,callback){

        axios.post(myConfig.api_url+`/api/v1/getGroupesByMatCadAnn?api_token=${token}`,args)
        .then((response) => {
            if (response.status == 200) {
                callback(response.data);
            }
            else{
                callback(null);
            }
        })
        .catch(() => {
            callback(null);
        });

    },

    //groupe_idgroupe:?, categorie_idcategorie:?
    createGroupeCategorie(args,callback){

        axios.post(myConfig.api_url+`/api/v1/createGroupeCategorie?api_token=${token}`,args)
        .then((response) => {
            if (response.status == 200) {
                callback(response.data);
            }
            else{
                callback(null);
            }
        })
        .catch(() => {
            callback(null);
        });

    },

    //idgroupe:?, idcategorie:?
    updateGroupeCategorie(args,callback){

        axios.post(myConfig.api_url+`/api/v1/updateGroupeCategorie?api_token=${token}`,args)
        .then((response) => {
            if (response.status == 200) {
                callback(response.data);
            }
            else{
                callback(null);
            }
        })
        .catch(() => {
            callback(null);
        });

    },

    //idgroupe:?, idcategorie:?
    deleteGroupeCategorie(args,callback){

        axios.post(myConfig.api_url+`/api/v1/deleteGroupeCategorie?api_token=${token}`,args)
        .then((response) => {
            if (response.status == 200) {
                callback(response.data);
            }
            else{
                callback(null);
            }
        })
        .catch(() => {
            callback(null);
        });

    },

    //groupe_idgroupe:?, horaire_idhoraire:?
    createGroupeHoraire(args,callback){

        axios.post(myConfig.api_url+`/api/v1/createGroupeHoraire?api_token=${token}`,args)
        .then((response) => {
            if (response.status == 200) {
                callback(response.data);
            }
            else{
                callback(null);
            }
        })
        .catch(() => {
            callback(null);
        });

    },

    //idgroupe:?, idhoraire:?
    updateGroupeHoraire(args,callback){

        axios.post(myConfig.api_url+`/api/v1/updateGroupeHoraire?api_token=${token}`,args)
        .then((response) => {
            if (response.status == 200) {
                callback(response.data);
            }
            else{
                callback(null);
            }
        })
        .catch(() => {
            callback(null);
        });

    },

    //idgroupe:?, idhoraire:?
    deleteGroupeHoraire(args,callback){

        axios.post(myConfig.api_url+`/api/v1/deleteGroupeHoraire?api_token=${token}`,args)
        .then((response) => {
            if (response.status == 200) {
                callback(response.data);
            }
            else{
                callback(null);
            }
        })
        .catch(() => {
            callback(null);
        });

    },

    //groupe_idgroupe:?, periode_idperiode:?, nbr_heures:?
    createGroupePeriode(args,callback){

        axios.post(myConfig.api_url+`/api/v1/createGroupePeriode?api_token=${token}`,args)
        .then((response) => {
            if (response.status == 200) {
                callback(response.data);
            }
            else{
                callback(null);
            }
        })
        .catch(() => {
            callback(null);
        });

    },

    //idgroupe:?, idperiode:?
    updateGroupePeriode(args,callback){

        axios.post(myConfig.api_url+`/api/v1/updateGroupePeriode?api_token=${token}`,args)
        .then((response) => {
            if (response.status == 200) {
                callback(response.data);
            }
            else{
                callback(null);
            }
        })
        .catch(() => {
            callback(null);
        });

    },

    //idgroupe:?, idperiode:?
    deleteGroupePeriode(args,callback){

        axios.post(myConfig.api_url+`/api/v1/deleteGroupePeriode?api_token=${token}`,args)
        .then((response) => {
            if (response.status == 200) {
                callback(response.data);
            }
            else{
                callback(null);
            }
        })
        .catch(() => {
            callback(null);
        });

    },

    add(args,callback){

        //annee_idannee:?, matiere_idmatiere:?, niveau:?, cadence_hebdo_idcadence_hebdo:?, documentation_iddocumentation:?, prof_par_defaut:?, salle_idsalle:?,
        axios.post(myConfig.api_url+`/api/v1/addGroupe?api_token=${token}`,args)
        .then((response) => {
            if (response.status == 200) {
                callback(response.data);
            }
            else{
                callback(null);
            }
        })
        .catch(() => {
            callback(null);
        });

    },

    update(args,callback){
        // id:?, annee_idannee:?, matiere_idmatiere:?, niveau:?, cadence_hebdo_idcadence_hebdo:?, documentation_iddocumentation:?, prof_par_defaut:?, salle_idsalle:?,
        axios.post(myConfig.api_url+`/api/v1/updateGroupe?api_token=${token}`,args)
        .then((response) => {
            if (response.status == 200) {
                callback(response.data);
            }
            else{
                callback(null);
            }
        })
        .catch(() => {
            callback(null);
        });

    },

    delete(args,callback){
        //id:? ,
        axios.post(myConfig.api_url+`/api/v1/deleteGroupe?api_token=${token}`,args)
        .then((response) => {
            if (response.status == 200) {
                callback(response.data);
            }
            else{
                callback(null);
            }
        })
        .catch(() => {
            callback(null);
        });

    },

}