import myConfig from './myConfig';
import axios from 'axios';

const token = localStorage.getItem('token');
export default {
    getAllCategories(callback){

    axios.get(myConfig.api_url+`/api/v1/allCategories?api_token=${token}`)
        .then((response) => {
            if (response.status == 200) {
                callback(response.data);
            }
            else{
                callback(null);
            }
        })
        .catch(() => {
            callback(null);
        });

    },

    addCategorie(args,callback){


        axios.post(myConfig.api_url+`/api/v1/addCategorie?api_token=${token}`,args)
        .then((response) => {
            if (response.status == 200) {
                callback(response.data);
            }
            else{
                callback(null);
            }
        })
        .catch(() => {
            callback(null);
        });

    },

    updateCategorie(args,callback){

        axios.post(myConfig.api_url+`/api/v1/updateCategorie?api_token=${token}`,args)
        .then((response) => {
            if (response.status == 200) {
                callback(response.data);
            }
            else{
                callback(null);
            }
        })
        .catch(() => {
            callback(null);
        });

    },

    deleteCategorie(args,callback){

        axios.post(myConfig.api_url+`/api/v1/deleteCategorie?api_token=${token}`,args)
        .then((response) => {
            if (response.status == 200) {
                callback(response.data);
            }
            else{
                callback(null);
            }
        })
        .catch(() => {
            callback(null);
        });

    },

}