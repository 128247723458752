<template>
  <div class="facture">
    <!-- modal inscription client -->
        <div
          class="modal fade"
          id="InscriptionModal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-xl" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Client : {{formI.idclient}}, {{formI.nom}} {{formI.prenom}}</h5>
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  @click="closeModalInscription"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body"> 
                <div class="row" style="border: 1px solid;padding: 15px;margin: 4px;">
                <div class="clearfix">
                    <button
                    @click="NewInscriptionModal"
                      type="button"
                      class="waves-effect waves-light btn mb-5 bg-gradient-warning" 
                      style="margin-left: 19px;"
                    >
                      Ajouter
                    </button> 
                </div>
                <h4>Frais Inscription : <span>{{tarif_frais_inscription}} DHs </span></h4>
                <div class="table-responsive">
                    <div
                      id="example_wrapper"
                      class="dataTables_wrapper container-fluid dt-bootstrap4 scrollable"
                    >
                      <table
                        id="inscription"
                        class="table table-bordered table-striped dataTable"
                        data-page-size="10"
                      >
                        <thead>
                          <tr>
                            <th>Convention</th>
                            <th>Matière</th>
                            <th>Cadence</th>
                            <th>Tarif</th>
                            <th>Groupe</th> 
                            <th>Periodes</th> 
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="ins,i of inscription" :key="i">
                            <th>{{ins.organisme}}</th> 
                            <th>{{ins.matiere}}</th> 
                            <th>{{ins['nbr-heures'] +'h/sem'}}</th>  
                            <th>{{ins.montant}}</th> 
                            <th>{{ins.groupe_idgroupe?'Gr N°' + ins.groupe_idgroupe + ", " + AllGroupes[AllGroupes.map(v=>v.idgroupe.toString()).indexOf(ins.groupe_idgroupe.toString())].niveau:'Non affecter'}}</th> 
                            <th>{{ins.periodes}}</th>
                            <th>
                              <button @click="UpdateGroupeModal(ins)" class="waves-effect waves-circle btn btn-social-icon btn-circle btn-info">
                                <i title="modifier groupe" style="cursor:pointer;" class="ti-pencil btnDelete" aria-hidden="true"></i>
                              </button>
                              <button :disabled="role!=1&&role!=2" @click="DeleteInscModal(ins.idinscription)" class="waves-effect waves-circle btn btn-social-icon btn-circle btn-danger">
                                <i title="Supprimer" style="cursor:pointer;" class="ti-trash btnDelete" aria-hidden="true"></i>
                              </button>
                            </th> 
                          </tr>
                        </tbody>
                      </table>
                    </div>
                </div>
                <label>Total : <span>{{total_a_payer + tarif_frais_inscription}}</span></label>
                <div class="row">
                <div class="col-9">
                  <div> 
                    <label class="form-label">    </label>
                    <input
                      type="text" 
                      class="form-control" 
                      style="margin-top:8px"
                    />
                  </div>
                </div>
                <div class="col-3">
                  <div>
                    <label class="form-label">Remise</label>
                    <input
                      type="text" 
                      class="form-control" 
                      v-model="facture.remise"
                    />
                  </div>
                </div>
                </div> 
                  <h4 style="padding-top:10px">
                    Net à payer : {{(total_a_payer + tarif_frais_inscription)-(facture.remise?facture.remise:0)}} dhs
                    <button @click="AddRemise" type="button" class="btn btn-primary float-end" id="deleteclient" >
                      Enregistrer
                    </button>
                  </h4> 
                </div>
                <div class="row">
                  <div class="col-8" style="border: 1px solid;"> 
                    <h3>Paiements</h3>
                  <div class="clearfix">
                      <button
                        type="button"
                        class="waves-effect waves-light btn mb-5 btn-info" 
                        style="margin-left: 19px;"
                        @click="AddEscpeceModal"
                      >
                        Espèces
                      </button> 
                      <button
                        type="button"
                        class="waves-effect waves-light btn mb-5 btn-info" 
                        style="margin-left: 19px;"
                        @click="ChooseChequeModal"
                      >
                        Choisir chèque
                      </button> 
                      <button
                        type="button"
                        class="waves-effect waves-light btn mb-5 btn-info" 
                        style="margin-left: 19px;"
                        @click="AddCarteModal"
                      >
                        Carte bancaire
                      </button> 
                      <button
                        type="button"
                        class="waves-effect waves-light btn mb-5 btn-info" 
                        style="margin-left: 19px;"
                        @click="AddVirementModal"
                      >
                        Virement bancaire
                      </button> 
                  </div> 
                  <div class="table-responsive">
                      <div
                        id="example_wrapper"
                        class="dataTables_wrapper container-fluid dt-bootstrap4 scrollable"
                      >
                        <table
                          id="Paiements"
                          class="table table-bordered table-striped dataTable"
                          data-page-size="10"
                        >
                          <thead>
                            <tr>
                              <th>Montant</th>
                              <th>Date</th>
                              <th>Type</th>  
                              <th>Utilisateur</th>  
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-for="p,i of paiement" :key="i">
                              <th>{{p.montant}}</th>
                              <th>{{p.date}}</th>
                              <th>{{p.type}}</th>
                              <th>{{p.email}}</th>
                              <th><button :disabled="role!=1&&role!=2" @click="DeletePaiementModal(p.id,p.type,p.montant,p.banque)" class="waves-effect waves-circle btn btn-social-icon btn-circle btn-danger"><i title="Supprimer" style="cursor:pointer;" class="ti-trash btnDelete" aria-hidden="true"></i></button></th> 
                            </tr>
                          </tbody>
                        </table>
                      </div>
                  </div>
                  <h4 style="color:green">Paiements : {{total_paiement}} dhs</h4> 
                  </div>
                  <div class="col-4" style="border: 1px solid;">  
                  <h3>Reste à payer</h3>
                <div class="clearfix">
                    <button
                      type="button"
                      class="waves-effect waves-light btn mb-5  btn-info" 
                      style="margin-left: 19px;"
                      @click="AddResterAPayerModal"
                    >
                      Ajouter
                    </button> 
                </div>
                <div class="table-responsive">
                    <div
                      id="example_wrapper"
                      class="dataTables_wrapper container-fluid dt-bootstrap4 scrollable"
                    >
                      <table
                        id="Reste"
                        class="table table-bordered table-striped dataTable"
                        data-page-size="10"
                      >
                        <thead>
                          <tr>
                            <th>Montant</th>
                            <th>Date</th> 
                            <th>Modifié num</th> 
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="r,i of reste_a_paye" :key="i">
                            <th>{{r.montant}}</th>
                            <th :style="r.date==null?'background-color:orange':''">{{r.date}}</th>
                            <th>{{r.updated_num}}</th>
                            <th>
                              <button @click="DeletePaiementModal(r['idreste-a-payer'],'aPayer',0)" class="waves-effect waves-circle btn btn-social-icon btn-circle btn-danger">
                                <i title="Supprimer" style="cursor:pointer;" class="ti-trash btnDelete" aria-hidden="true"></i>
                              </button>
                              <button @click="PayerResteAPayer(r['idreste-a-payer'],r.montant)" class="waves-effect waves-circle btn btn-social-icon btn-circle btn-success">
                                <i title="Supprimer" style="cursor:pointer;" class="ti-money" aria-hidden="true"></i>
                              </button>
                              <button @click="UpdateResterAPayerModal(r)" class="waves-effect waves-circle btn btn-social-icon btn-circle btn-info">
                                <i title="update" style="cursor:pointer;" class="ti-pencil" aria-hidden="true"></i>
                              </button>
                            </th> 
                          </tr>
                        </tbody>
                      </table>
                    </div>
                </div>
                  <h4> <span :style="true_total_reste_a_paye==0?'color:blue':''"> Reste à payer : {{true_total_reste_a_paye}} dhs </span><span v-if="total_reste_a_paye>0" style="color:red">({{total_reste_a_paye}})</span></h4> 
                </div>
                </div> 
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-danger" data-dismiss="modal" @click="closeModalInscription">
                  Annuler
                </button>
                <button v-if="role==1||role==2" @click="deleteFactureModal" type="button" class="btn btn-danger float-end" >
                  Suprimmer
                </button>
                <button v-if="total_reste_a_paye<=0" @click="imprimer" type="button" class="btn btn-primary float-end" >
                  Imprimer recu
                </button>
              </div>
            </div>
          </div>
        </div>
        <!-- /.fin modal inscription client -->

        <!-- new inscription -->
        <div
          class="modal fade"
          id="NewInscriptionModal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-xl" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Ajouter une inscription</h5>
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  @click="closeModalNewInscription"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body"> 
                <div class="row">
                  <div class="col-6" style="border: 1px solid;"> 
                  <h3>Inscription</h3>
                  <div class="form-group">
                    <div>
                      <label class="form-label">Convention:</label>
                      <div :class="v.formInscription.convention.$errors.length==0?'input-group mb-15':'input-group mb-15 error-border'">
                        <select @change="selectGroupes" class="form-select" v-model="formInscription.convention">
                          <option :value="null">Choisie</option>
                          <option
                            v-for="m of Conventions"
                            :value="m['idconvention']"
                            :key="m['idconvention']"
                            :selected="m['par-defaut']==1?true:false"
                            >{{m.organisme}}</option> 
                        </select>
                      </div>
                      <div class="input-errors" v-for="error of v.formInscription.convention.$errors" :key="error.$uid">
                        <div class="error-msg">{{ error.$message }}</div>
                      </div> 
                    </div>
                  </div>
                  <div class="form-group">
                    <div>
                      <label class="form-label">Matiere:</label>
                      <div :class="v.formInscription.matiere.$errors.length==0?'input-group mb-15':'input-group mb-15 error-border'">
                        <select @change="selectGroupes" class="form-select" v-model="formInscription.matiere">
                          <option :value="null">Choisie</option>
                          <option
                            v-for="m of Matieres"
                            :value="m['idmatiere']"
                            :key="m['idmatiere']"
                            >{{m.designation}}</option> 
                        </select>
                      </div>
                      <div class="input-errors" v-for="error of v.formInscription.matiere.$errors" :key="error.$uid">
                        <div class="error-msg">{{ error.$message }}</div>
                      </div> 
                    </div>
                  </div>
                  <div class="form-group">
                    <div>
                      <label class="form-label">Cadence hebdomadaire:</label>
                      <div :class="v.formInscription.cadence.$errors.length==0?'input-group mb-15':'input-group mb-15 error-border'">
                        <select @change="selectGroupes" id="cadence" class="form-select" v-model="formInscription.cadence">
                          <option :value="null">Choisie</option>
                          <option
                            v-for="m of Cadences"
                            :value="m['idcadence-hebdo']"
                            :key="m['idmatiere-hebdo']"
                            >{{m["nbr-heures"]}}</option> 
                        </select>
                      </div>
                      <div class="input-errors" v-for="error of v.formInscription.cadence.$errors" :key="error.$uid">
                        <div class="error-msg">{{ error.$message }}</div>
                      </div> 
                    </div>
                  </div>
                  <div class="form-group">
                    <div>
                      <label class="form-label">Categorie:</label>
                      <div :class="v.formInscription.cat.$errors.length==0?'input-group mb-15':'input-group mb-15 error-border'">
                        <select @change="selectGroupes" id="cat" class="form-select" v-model="formInscription.cat">
                          <option :value="null">Choisie</option>
                          <option
                            v-for="m of Categories"
                            :value="m['idcategorie']"
                            :key="m['idcategorie']"
                            >{{m.designation}}</option> 
                        </select>
                      </div>
                      <div class="input-errors" v-for="error of v.formInscription.cat.$errors" :key="error.$uid">
                        <div class="error-msg">{{ error.$message }}</div>
                      </div> 
                    </div>
                  </div>
                  <div class="form-group">
                    <div>
                      <label class="form-label">Periodes:</label>
                      
                       <Multiselect
                        v-model="Periodesvalue"
                        :options="Periodes"
                        valueProp="idperiode"
                        label="designation"
                        trackBy="designation"
                        placeholder="Choisie Periodes"
                        mode="tags"
                        @change="ChangePeriodes"
                      />
                    </div>
                  </div>
                  <div class="form-group">
                    <div>
                      <label class="form-label">Date debut:</label>
                      <div :class="v.DebutDate.$errors.length==0?'input-group mb-15':'input-group mb-15 error-border'">
                        <input
                          type="date"
                          class="form-control"
                          @change="calculer"
                          v-model="DebutDate"
                        />
                      </div>
                      <div class="input-errors" v-for="error of v.DebutDate.$errors" :key="error.$uid">
                        <div class="error-msg">{{ error.$message }}</div>
                      </div> 
                    </div>
                  </div>
                  </div>
                  <div class="col-6" style="border: 1px solid;">  
                  <h3>Affectaion</h3>
                  <div class="col-sm-12">
                    <div class="form-group" style="position:relative">
                      <input
                        type="checkbox"
                        style="left:0;width:18px;height:18px;z-index: 100;cursor:pointer;"
                        v-model="formInscription.NonAffecter"
                        @change="formInscription.tarifManuel=false"
                      />
                      <label class="form-label">Sans affectation</label>
                        
                    </div>
                  </div>
                  <div v-if="formInscription.NonAffecter==true" class="form-group">
                    <div>
                      <label class="form-label">Tarif:</label>
                      <div :class="v.formInscription.tarif.$errors.length==0?'input-group mb-15':'input-group mb-15 error-border'">
                        <input
                          type="number"
                          class="form-control"
                          v-model="formInscription.tarif"
                        />
                      </div>
                      <div class="input-errors" v-for="error of v.formInscription.tarif.$errors" :key="error.$uid">
                        <div class="error-msg">{{ error.$message }}</div>
                      </div> 
                    </div>
                  </div>
                  <div v-if="formInscription.NonAffecter==false" class="form-group">
                    <div>
                      <label class="form-label">Groupe:</label>
                      <vue-loaders-ball-beat
                      v-if="loaderGroupes"
                        color="red"
                        scale="1"
                        style="    float: right;"
                      ></vue-loaders-ball-beat>
                      <div :class="v.formInscription.groupe.$errors.length==0?'input-group mb-15':'input-group mb-15 error-border'">
                        <select :disabled="Groupes.length==0?true:false" @change="selectGroupeHoraire" class="form-select"  v-model="formInscription.groupe">
                          <option :value="null">Choisie</option>
                          <option
                            v-for="m of Groupes"
                          :value="m['idgroupe']"
                            :key="m['idgroupe']"
                            >{{m.niveau}}</option> 
                        </select>
                      </div>
                      <div class="input-errors" v-for="error of v.formInscription.groupe.$errors" :key="error.$uid">
                        <div class="error-msg">{{ error.$message }}</div>
                      </div> 
                    </div>
                  </div>
                  <h4 v-if="formInscription.NonAffecter==false">Horaires du groupe</h4> 
                  <div v-if="formInscription.NonAffecter==false" class="table-responsive">
                    <div
                      id="example_wrapper"
                      class="dataTables_wrapper container-fluid dt-bootstrap4 scrollable"
                    >
                      <table
                        id="Reste"
                        class="table table-bordered table-striped dataTable"
                        data-page-size="10"
                      >
                        <thead>
                          <tr>
                            <th>jour</th>
                            <th>Horaire</th> 
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="(g,i) of GroupeHoraires" :key="i">
                            <td>{{weekDays[g.jour]}}</td>
                            <td>{{"à "+g['heure-debut']+":"+g['min-debut']+" de "+g['heure-fin']+":"+g['min-fin']}}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                </div>
                </div>
                </div> 
                <div v-if="formInscription.NonAffecter==false" class="row">
                  <div class="col-12" style="border: 1px solid;"> 
                    <div class="col-sm-12">
                      <div class="form-group" style="position:relative">
                        <input
                          type="checkbox"
                          style="left:0;width:18px;height:18px;z-index: 100;cursor:pointer;"
                          v-model="formInscription.tarifManuel"
                        />
                        <label class="form-label">Tarif manuel</label>
                          
                      </div>
                    </div>
                    <h2 v-if="formInscription.tarifManuel==false">Tarif d'inscription : {{tarif}} DH </h2> 
                    <h2 v-if="formInscription.tarifManuel==true">
                      <div class="form-group">
                        <div>
                          <label class="form-label">Tarif:</label>
                          <div :class="v.formInscription.tarifManuelValue.$errors.length==0?'input-group mb-15':'input-group mb-15 error-border'">
                            <input
                              type="number"
                              class="form-control"
                              v-model="formInscription.tarifManuelValue"
                            />
                          </div>
                          <div class="input-errors" v-for="error of v.formInscription.tarifManuelValue.$errors" :key="error.$uid">
                            <div class="error-msg">{{ error.$message }}</div>
                          </div> 
                        </div>
                      </div>
                    </h2> 
                  </div>
                </div>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-danger" data-dismiss="modal" @click="closeModalNewInscription">
                  Annuler
                </button>
                <button type="button" @click="addInscription" class="btn btn-primary float-end" id="deleteclient" >
                  Ajouter
                </button>
              </div>
            </div>
          </div>
        </div>
        <!-- /.fin modal new inscription  -->

        <!-- modal Supprimer inscription -->
        <div
          class="modal fade"
          id="DeleteInscModal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Supprimer inscription</h5>
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  @click="closeModalDeleteInsc"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <h4>Voulez-vous vraiment supprimer cette inscription?</h4>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-danger" data-dismiss="modal" @click="closeModalDeleteInsc">
                  Non
                </button>
                <button type="button" class="btn btn-primary float-end" @click="deleteInscription">
                  Oui
                </button>
              </div>
            </div>
          </div>
        </div>
        <!-- /.fin modal Supprimer inscription -->

         <!-- modal Ajouter paiement especes -->
        <div
          class="modal fade"
          id="AddEscpeceModal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Ajouter paiement Espèces </h5>
              </div>
              <div class="modal-body">
                <div class="col-12">
                  <div class="box">
                    <form class="form" @submit.prevent="submitForm">
                      <div class="box-body"> 
                        <div class="row"> 
                          <div class="col-sm-12">
                            <div class="form-group">
                              <label class="form-label">Montant</label>
                              <div :class="v.formAddEspece.montant.$errors.length==0?'input-group mb-15':'input-group mb-15 error-border'">
                                
                                <input
                                  type="number"
                                  placeholder="Montant..."
                                  class="form-control"
                                  v-model="formAddEspece.montant"
                                  @input="v.formAddEspece.montant.$touch()"
                                />
                              </div>
                              <div class="input-errors" v-for="error of v.formAddEspece.montant.$errors" :key="error.$uid">
                                <div class="error-msg">{{ error.$message }}</div>
                              </div> 
                            </div>
                          </div>
                          <div class="col-sm-12">
                            <div class="form-group">
                              <label class="form-label">Date</label>
                              <div :class="v.formAddEspece.date.$errors.length==0?'input-group mb-15':'input-group mb-15 error-border'">
                                
                                <input
                                  type="date"
                                  placeholder="Date..."
                                  class="form-control"
                                  v-model="formAddEspece.date"
                                  @input="v.formAddEspece.date.$touch()"
                                />
                              </div>
                              <div class="input-errors" v-for="error of v.formAddEspece.date.$errors" :key="error.$uid">
                                <div class="error-msg">{{ error.$message }}</div>
                              </div> 
                            </div>
                          </div>
                        </div> 
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div class="modal-footer">
                <button
                  type="button"
                  class="btn btn-danger"
                  data-dismiss="modal"
                  @click="closeAddEscpeceModal"
                >
                  Close
                </button>
                <button type="button" class="btn btn-primary float-end" id="addcentre" @click="AddEscpece">
                  Enregistrer
                </button>
              </div>
            </div>
          </div>
        </div>
        <!-- /.fin modal paiement especes -->

        

        <!-- modal paiement carte -->
        <div
          class="modal fade"
          id="AddCarteModal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Ajouter paiement carte bancaire </h5>
              </div>
              <div class="modal-body">
                <div class="col-12">
                  <div class="box">
                    <form class="form" @submit.prevent="submitForm">
                      <div class="box-body"> 
                        <div class="row"> 
                          <div class="col-sm-12">
                            <div class="form-group">
                              <label class="form-label">Compte</label>
                              <div :class="v.formAddCarte.banque.$errors.length==0?'input-group mb-15':'input-group mb-15 error-border'">
                                
                                <select class="form-select" v-model="formAddCarte.banque">
                                  <option :value="null" disabled>Choisie Compte</option> 
                                  <option
                                  :value="b.id"
                                   v-for="b in Utilisateurs"
                                   :key="b.id"
                                   >{{b.email}}</option> 
                                </select>
                              </div>
                              <div class="input-errors" v-for="error of v.formAddCarte.banque.$errors" :key="error.$uid">
                                <div class="error-msg">{{ error.$message }}</div>
                              </div> 
                            </div>
                          </div>
                          <div class="col-sm-12">
                            <div class="form-group">
                              <label class="form-label">Montant</label>
                              <div :class="v.formAddCarte.montant.$errors.length==0?'input-group mb-15':'input-group mb-15 error-border'">
                                
                                <input
                                  type="number"
                                  placeholder="Montant..."
                                  class="form-control"
                                  v-model="formAddCarte.montant"
                                  @input="v.formAddCarte.montant.$touch()"
                                />
                              </div>
                              <div class="input-errors" v-for="error of v.formAddCarte.montant.$errors" :key="error.$uid">
                                <div class="error-msg">{{ error.$message }}</div>
                              </div> 
                            </div>
                          </div>
                          <div class="col-sm-12">
                            <div class="form-group">
                              <label class="form-label">Date</label>
                              <div :class="v.formAddCarte.date.$errors.length==0?'input-group mb-15':'input-group mb-15 error-border'">
                                
                                <input
                                  type="date"
                                  placeholder="Date..."
                                  class="form-control"
                                  v-model="formAddCarte.date"
                                  @input="v.formAddCarte.date.$touch()"
                                />
                              </div>
                              <div class="input-errors" v-for="error of v.formAddCarte.date.$errors" :key="error.$uid">
                                <div class="error-msg">{{ error.$message }}</div>
                              </div> 
                            </div>
                          </div>
                        </div> 
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div class="modal-footer">
                <button
                  type="button"
                  class="btn btn-danger"
                  data-dismiss="modal"
                  @click="closeAddCarteModal"
                >
                  Close
                </button>
                <button type="button" class="btn btn-primary float-end" id="addcentre" @click="AddCarte">
                  Enregistrer
                </button>
              </div>
            </div>
          </div>
        </div>
        <!-- /.fin modal paiement carte -->

        <!-- modal virement carte -->
        <div
          class="modal fade"
          id="AddVirementModal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Ajouter virement bancaire </h5>
              </div>
              <div class="modal-body">
                <div class="col-12">
                  <div class="box">
                    <form class="form" @submit.prevent="submitForm">
                      <div class="box-body"> 
                        <div class="row"> 
                          <div class="col-sm-12">
                            <div class="form-group">
                              <label class="form-label">Compte</label>
                              <div :class="v.formAddVirement.banque.$errors.length==0?'input-group mb-15':'input-group mb-15 error-border'">
                                
                                <select class="form-select" v-model="formAddVirement.banque">
                                  <option :value="null" disabled>Choisie Compte</option> 
                                  <option
                                  :value="b.id"
                                   v-for="b in Utilisateurs"
                                   :key="b.id"
                                   >{{b.email}}</option> 
                                </select>
                              </div>
                              <div class="input-errors" v-for="error of v.formAddVirement.banque.$errors" :key="error.$uid">
                                <div class="error-msg">{{ error.$message }}</div>
                              </div> 
                            </div>
                          </div>
                          <div class="col-sm-12">
                            <div class="form-group">
                              <label class="form-label">Montant</label>
                              <div :class="v.formAddVirement.montant.$errors.length==0?'input-group mb-15':'input-group mb-15 error-border'">
                                
                                <input
                                  type="number"
                                  placeholder="Montant..."
                                  class="form-control"
                                  v-model="formAddVirement.montant"
                                  @input="v.formAddVirement.montant.$touch()"
                                />
                              </div>
                              <div class="input-errors" v-for="error of v.formAddVirement.montant.$errors" :key="error.$uid">
                                <div class="error-msg">{{ error.$message }}</div>
                              </div> 
                            </div>
                          </div>
                          <div class="col-sm-12">
                            <div class="form-group">
                              <label class="form-label">Date</label>
                              <div :class="v.formAddVirement.date.$errors.length==0?'input-group mb-15':'input-group mb-15 error-border'">
                                
                                <input
                                  type="date"
                                  placeholder="Date..."
                                  class="form-control"
                                  v-model="formAddVirement.date"
                                  @input="v.formAddVirement.date.$touch()"
                                />
                              </div>
                              <div class="input-errors" v-for="error of v.formAddVirement.date.$errors" :key="error.$uid">
                                <div class="error-msg">{{ error.$message }}</div>
                              </div> 
                            </div>
                          </div>
                        </div> 
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div class="modal-footer">
                <button
                  type="button"
                  class="btn btn-danger"
                  data-dismiss="modal"
                  @click="closeAddVirementModal"
                >
                  Close
                </button>
                <button type="button" class="btn btn-primary float-end" id="addcentre" @click="AddVirement">
                  Enregistrer
                </button>
              </div>
            </div>
          </div>
        </div>
        <!-- /.fin modal virement carte -->

        <!-- modal Ajouter rester a payer -->
        <div
          class="modal fade"
          id="AddResterAPayerModal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Ajouter montant a payer </h5>
              </div>
              <div class="modal-body">
                <span :style="(true_total_reste_a_paye - resterAPayerArray.map(v=>parseFloat(v.montant)).reduce((a, b) => a + b, 0))==0?'color:blue':''"> Reste à payer : {{true_total_reste_a_paye - resterAPayerArray.map(v=>parseFloat(v.montant)).reduce((a, b) => a + b, 0)}} dhs </span>
                
                <div class="col-12">
                  <div class="box">
                    <form class="form" @submit.prevent="submitForm">
                      <div class="box-body"> 
                        <div class="row" v-for="r,i of resterAPayerArray" :key="i"> 
                          <div class="col-sm-5">
                            <div class="form-group">
                              <label class="form-label">Montant</label>
                              <div class="input-group mb-15">
                                <input
                                  type="number"
                                  placeholder="Montant..."
                                  class="form-control"
                                  v-model="r.montant"
                                />
                              </div>
                            </div>
                          </div>
                          <div class="col-sm-5">
                            <div class="form-group">
                              <label class="form-label">Date</label>
                              <div class="input-group mb-15">
                                <input
                                  type="date"
                                  placeholder="Date..."
                                  class="form-control"
                                  v-model="r.date"
                                  :min="today"
                                />
                              </div>
                            </div>
                          </div>
                          <div class="col-sm-2" style="display:flex;align-items:center">
                            <button class="waves-effect waves-circle btn btn-social-icon btn-circle btn-danger" @click="resterAPayerArray.splice(i,1)"> <i title="Inscription" style="cursor:pointer;" class="ti-close" aria-hidden="true"></i></button>
                          </div>
                        </div> 
                        <div>
                          <button class="waves-effect waves-circle btn btn-social-icon btn-circle btn-success" @click="resterAPayerArray.push({montant:0,date:''})"> <i title="Inscription" style="cursor:pointer;" class="ti-plus" aria-hidden="true"></i></button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div class="modal-footer">
                <button
                  type="button"
                  class="btn btn-danger"
                  data-dismiss="modal"
                  @click="closeAddResterAPayerModal"
                >
                  Close
                </button>
                <button type="button" class="btn btn-primary float-end" id="addcentre" @click="AddResterAPayer">
                  Enregistrer
                </button>
              </div>
            </div>
          </div>
        </div>
        <!-- /.fin modal rester a payer -->
        <!-- modal coisir cheque -->
        <div
          class="modal fade"
          id="ChooseChequeModal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Choisir chèque </h5>
              </div>
              <div class="modal-body">
                <div class="col-12">
                  <div class="box">
                    <form class="form" @submit.prevent="submitForm">
                      <div class="box-body"> 
                        <div class="row"> 
                          <div class="col-sm-12">
                            <div class="form-group">
                              <label class="form-label">chèque</label>
                              <div :class="v.formChooseCheque.cheque.$errors.length==0?'input-group mb-15':'input-group mb-15 error-border'">
                                <Multiselect
                                  v-model="formChooseCheque.cheque"
                                  :options="cheques"
                                  label="label"
                                  trackBy="label"
                                  placeholder="Choisie chèque"
                                  valueProp="id"
                                  :searchable="true"
                                />
                              </div>
                              <div class="input-errors" v-for="error of v.formChooseCheque.cheque.$errors" :key="error.$uid">
                                <div class="error-msg">{{ error.$message }}</div>
                              </div> 
                            </div>
                          </div>
                          <div class="col-sm-12">
                            <div class="form-group">
                              <label class="form-label">Montant</label>
                              <div :class="v.formChooseCheque.montant.$errors.length==0?'input-group mb-15':'input-group mb-15 error-border'">
                                
                                <input
                                  type="number"
                                  placeholder="Montant..."
                                  class="form-control"
                                  v-model="formChooseCheque.montant"
                                  @input="v.formChooseCheque.montant.$touch()"
                                />
                              </div>
                              <div class="input-errors" v-for="error of v.formChooseCheque.montant.$errors" :key="error.$uid">
                                <div class="error-msg">{{ error.$message }}</div>
                              </div> 
                            </div>
                          </div>
                          <div class="col-sm-12">
                            <div class="form-group">
                              <label class="form-label">Date</label>
                              <div :class="v.formChooseCheque.date.$errors.length==0?'input-group mb-15':'input-group mb-15 error-border'">
                                
                                <input
                                  type="date"
                                  placeholder="Date..."
                                  class="form-control"
                                  v-model="formChooseCheque.date"
                                  @input="v.formChooseCheque.date.$touch()"
                                />
                              </div>
                              <div class="input-errors" v-for="error of v.formChooseCheque.date.$errors" :key="error.$uid">
                                <div class="error-msg">{{ error.$message }}</div>
                              </div> 
                            </div>
                          </div>
                        </div> 
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div class="modal-footer">
                <button
                  type="button"
                  class="btn btn-danger"
                  data-dismiss="modal"
                  @click="closeChooseChequeModal"
                >
                  Close
                </button>
                <button
                  type="button"
                  class="waves-effect waves-light btn mb-5 btn-info" 
                  style="margin-left: 19px;"
                  @click="AddChequeModal"
                >
                  Ajouter chèque
                </button> 
                <button type="button" class="btn btn-primary float-end" id="addcentre" @click="ChooseCheque">
                  Enregistrer
                </button>
              </div>
            </div>
          </div>
        </div>
        <!-- /.fin modal choisir cheque -->

                <!-- modal update rester a payer -->
        <div
          class="modal fade"
          id="UpdateResterAPayerModal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Update montant a payer </h5>
              </div>
              <div class="modal-body">
                
                <div class="col-12">
                  <div class="box">
                    <form class="form" @submit.prevent="submitForm">
                      <div class="box-body"> 
                        <div class="row"> 
                          <div class="col-sm-6">
                            <div class="form-group">
                              <label class="form-label">Montant</label>
                              <div class="input-group mb-15">
                                <input
                                  type="number"
                                  placeholder="Montant..."
                                  class="form-control"
                                  v-model="formUpdateResteAPayer.montant"
                                />
                              </div>
                            </div>
                          </div>
                          <div class="col-sm-6">
                            <div class="form-group">
                              <label class="form-label">Date</label>
                              <div class="input-group mb-15">
                                <input
                                  type="date"
                                  placeholder="Date..."
                                  class="form-control"
                                  v-model="formUpdateResteAPayer.date"
                                  :min="today"
                                />
                              </div>
                            </div>
                          </div>
                        </div> 
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div class="modal-footer">
                <button
                  type="button"
                  class="btn btn-danger"
                  data-dismiss="modal"
                  @click="closeUpdateResterAPayerModal"
                >
                  Close
                </button>
                <button type="button" class="btn btn-primary float-end" id="addcentre" @click="UpdateResterAPayer">
                  Enregistrer
                </button>
              </div>
            </div>
          </div>
        </div>
        <!-- /.fin modal update rester a payer -->

        <!-- modal Supprimer paiement -->
        <div
          class="modal fade"
          id="DeletePaiementModal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Supprimer paiement</h5>
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  @click="closeDeletePaiementModal"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <h4>Voulez-vous vraiment supprimer cette paiement?</h4>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-danger" data-dismiss="modal" @click="closeDeletePaiementModal">
                  Non
                </button>
                <button type="button" class="btn btn-primary float-end" @click="deletePaiement">
                  Oui
                </button>
              </div>
            </div>
          </div>
        </div>
        <!-- /.fin modal Supprimer paiement -->

        <!-- modal paiement de rester a payer -->
        <div
          class="modal fade"
          id="PayerResteAPayer"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Paiement montant rester a payer</h5>
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  @click="closePayerResteAPayer"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <h4>Choisir mode de Paiement :</h4>
                <div class="clearfix">
                  <button
                    type="button"
                    class="waves-effect waves-light btn mb-5 btn-info" 
                    style="margin-left: 19px;"
                  >
                    Espèces
                  </button> 
                  <button
                    type="button"
                    class="waves-effect waves-light btn mb-5 btn-info" 
                    style="margin-left: 19px;"
                    @click="AddCarteModal2"
                  >
                    Carte bancaire
                  </button> 
                  <button
                    type="button"
                    class="waves-effect waves-light btn mb-5 btn-info" 
                    style="margin-left: 19px;"
                    @click="AddVirementModal2"
                  >
                    Virement bancaire
                  </button> 
                  <button
                    type="button"
                    class="waves-effect waves-light btn mb-5 btn-info" 
                    style="margin-left: 19px;"
                    @click="ChooseChequeModal2"
                  >
                    Chèque
                  </button> 
                </div> 
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-danger" data-dismiss="modal" @click="closePayerResteAPayer">
                  Non
                </button>
                <button type="button" class="btn btn-primary float-end" @click="Payer">
                  Oui
                </button>
              </div>
            </div>
          </div>
        </div>
        <!-- /.fin modal paiement de rester a payer -->

        <!-- modal Ajouter paiement reste a payer especes -->
        <div
          class="modal fade"
          id="AddEscpeceModal2"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Paiement de reste à payer Espèces </h5>
              </div>
              <div class="modal-body">
                <div class="col-12">
                  <div class="box">
                    <form class="form" @submit.prevent="submitForm">
                      <div class="box-body"> 
                        <div class="row"> 
                          <div class="col-sm-12">
                            <div class="form-group">
                              <label class="form-label">Montant</label>
                              <div :class="v.formAddEspece2.montant.$errors.length==0?'input-group mb-15':'input-group mb-15 error-border'">
                                
                                <input
                                  type="number"
                                  placeholder="Montant..."
                                  class="form-control"
                                  v-model="formAddEspece2.montant"
                                  @input="v.formAddEspece2.montant.$touch()"
                                />
                              </div>
                              <div class="input-errors" v-for="error of v.formAddEspece2.montant.$errors" :key="error.$uid">
                                <div class="error-msg">{{ error.$message }}</div>
                              </div> 
                            </div>
                          </div>
                          <div class="col-sm-12">
                            <div class="form-group">
                              <label class="form-label">Date</label>
                              <div :class="v.formAddEspece2.date.$errors.length==0?'input-group mb-15':'input-group mb-15 error-border'">
                                
                                <input
                                  type="date"
                                  placeholder="Date..."
                                  class="form-control"
                                  v-model="formAddEspece2.date"
                                  @input="v.formAddEspece2.date.$touch()"
                                />
                              </div>
                              <div class="input-errors" v-for="error of v.formAddEspece2.date.$errors" :key="error.$uid">
                                <div class="error-msg">{{ error.$message }}</div>
                              </div> 
                            </div>
                          </div>
                        </div> 
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div class="modal-footer">
                <button
                  type="button"
                  class="btn btn-danger"
                  data-dismiss="modal"
                  @click="closeAddEscpeceModal2"
                >
                  Close
                </button>
                <button type="button" class="btn btn-primary float-end" id="addcentre" @click="AddEscpece2">
                  Enregistrer
                </button>
              </div>
            </div>
          </div>
        </div>
        <!-- /.fin modal paiement reste a payer especes -->
        <!-- modal paiement reste a payer virement carte -->
        <div
          class="modal fade"
          id="AddVirementModal2"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Paiement reste à payer par virement bancaire </h5>
              </div>
              <div class="modal-body">
                <div class="col-12">
                  <div class="box">
                    <form class="form" @submit.prevent="submitForm">
                      <div class="box-body"> 
                        <div class="row"> 
                          <div class="col-sm-12">
                            <div class="form-group">
                              <label class="form-label">Compte</label>
                              <div :class="v.formAddVirement2.banque.$errors.length==0?'input-group mb-15':'input-group mb-15 error-border'">
                                
                                <select class="form-select" v-model="formAddVirement2.banque">
                                  <option :value="null" disabled>Choisie Compte</option> 
                                  <option
                                  :value="b.id"
                                   v-for="b in Utilisateurs"
                                   :key="b.id"
                                   >{{b.email}}</option> 
                                </select>
                              </div>
                              <div class="input-errors" v-for="error of v.formAddVirement2.banque.$errors" :key="error.$uid">
                                <div class="error-msg">{{ error.$message }}</div>
                              </div> 
                            </div>
                          </div>
                          <div class="col-sm-12">
                            <div class="form-group">
                              <label class="form-label">Montant</label>
                              <div :class="v.formAddVirement2.montant.$errors.length==0?'input-group mb-15':'input-group mb-15 error-border'">
                                
                                <input
                                  type="number"
                                  placeholder="Montant..."
                                  class="form-control"
                                  v-model="formAddVirement2.montant"
                                  @input="v.formAddVirement2.montant.$touch()"
                                />
                              </div>
                              <div class="input-errors" v-for="error of v.formAddVirement2.montant.$errors" :key="error.$uid">
                                <div class="error-msg">{{ error.$message }}</div>
                              </div> 
                            </div>
                          </div>
                          <div class="col-sm-12">
                            <div class="form-group">
                              <label class="form-label">Date</label>
                              <div :class="v.formAddVirement2.date.$errors.length==0?'input-group mb-15':'input-group mb-15 error-border'">
                                
                                <input
                                  type="date"
                                  placeholder="Date..."
                                  class="form-control"
                                  v-model="formAddVirement2.date"
                                  @input="v.formAddVirement2.date.$touch()"
                                />
                              </div>
                              <div class="input-errors" v-for="error of v.formAddVirement2.date.$errors" :key="error.$uid">
                                <div class="error-msg">{{ error.$message }}</div>
                              </div> 
                            </div>
                          </div>
                        </div> 
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div class="modal-footer">
                <button
                  type="button"
                  class="btn btn-danger"
                  data-dismiss="modal"
                  @click="closeAddVirementModal2"
                >
                  Close
                </button>
                <button type="button" class="btn btn-primary float-end" id="addcentre" @click="AddVirement2">
                  Enregistrer
                </button>
              </div>
            </div>
          </div>
        </div>
        <!-- /.fin modal paiement reste a payer virement carte -->
        <!-- modal paiement reste a payer par carte -->
        <div
          class="modal fade"
          id="AddCarteModal2"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Paiement reste à payer par carte bancaire </h5>
              </div>
              <div class="modal-body">
                <div class="col-12">
                  <div class="box">
                    <form class="form" @submit.prevent="submitForm">
                      <div class="box-body"> 
                        <div class="row"> 
                          <div class="col-sm-12">
                            <div class="form-group">
                              <label class="form-label">Compte</label>
                              <div :class="v.formAddCarte2.banque.$errors.length==0?'input-group mb-15':'input-group mb-15 error-border'">
                                
                                <select class="form-select" v-model="formAddCarte2.banque">
                                  <option :value="null" disabled>Choisie Compte</option> 
                                  <option
                                  :value="b.id"
                                   v-for="b in Utilisateurs"
                                   :key="b.id"
                                   >{{b.email}}</option> 
                                </select>
                              </div>
                              <div class="input-errors" v-for="error of v.formAddCarte2.banque.$errors" :key="error.$uid">
                                <div class="error-msg">{{ error.$message }}</div>
                              </div> 
                            </div>
                          </div>
                          <div class="col-sm-12">
                            <div class="form-group">
                              <label class="form-label">Montant</label>
                              <div :class="v.formAddCarte2.montant.$errors.length==0?'input-group mb-15':'input-group mb-15 error-border'">
                                
                                <input
                                  type="number"
                                  placeholder="Montant..."
                                  class="form-control"
                                  v-model="formAddCarte2.montant"
                                  @input="v.formAddCarte2.montant.$touch()"
                                />
                              </div>
                              <div class="input-errors" v-for="error of v.formAddCarte2.montant.$errors" :key="error.$uid">
                                <div class="error-msg">{{ error.$message }}</div>
                              </div> 
                            </div>
                          </div>
                          <div class="col-sm-12">
                            <div class="form-group">
                              <label class="form-label">Date</label>
                              <div :class="v.formAddCarte2.date.$errors.length==0?'input-group mb-15':'input-group mb-15 error-border'">
                                
                                <input
                                  type="date"
                                  placeholder="Date..."
                                  class="form-control"
                                  v-model="formAddCarte2.date"
                                  @input="v.formAddCarte2.date.$touch()"
                                />
                              </div>
                              <div class="input-errors" v-for="error of v.formAddCarte2.date.$errors" :key="error.$uid">
                                <div class="error-msg">{{ error.$message }}</div>
                              </div> 
                            </div>
                          </div>
                        </div> 
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div class="modal-footer">
                <button
                  type="button"
                  class="btn btn-danger"
                  data-dismiss="modal" 
                  @click="closeAddCarteModal2"
                >
                  Close
                </button>
                <button type="button" class="btn btn-primary float-end" id="addcentre" @click="AddCarte2">
                  Enregistrer
                </button>
              </div>
            </div>
          </div>
        </div>
        <!-- /.fin modal paiement carte -->
        <!-- modal paiement reste a payer par cheque -->
        <div
          class="modal fade"
          id="ChooseChequeModal2"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Paiement reste à payer par chèque </h5>
              </div>
              <div class="modal-body">
                <div class="col-12">
                  <div class="box">
                    <form class="form" @submit.prevent="submitForm">
                      <div class="box-body"> 
                        <div class="row"> 
                          <div class="col-sm-12">
                            <div class="form-group">
                              <label class="form-label">chèque</label>
                              <div :class="v.formChooseCheque2.cheque.$errors.length==0?'input-group mb-15':'input-group mb-15 error-border'">
                                <Multiselect
                                  v-model="formChooseCheque2.cheque"
                                  :options="cheques"
                                  label="label"
                                  trackBy="label"
                                  placeholder="Choisie chèque"
                                  valueProp="id"
                                  :searchable="true"
                                />
                              </div>
                              <div class="input-errors" v-for="error of v.formChooseCheque2.cheque.$errors" :key="error.$uid">
                                <div class="error-msg">{{ error.$message }}</div>
                              </div> 
                            </div>
                          </div>
                          <div class="col-sm-12">
                            <div class="form-group">
                              <label class="form-label">Montant</label>
                              <div :class="v.formChooseCheque2.montant.$errors.length==0?'input-group mb-15':'input-group mb-15 error-border'">
                                
                                <input
                                  type="number"
                                  placeholder="Montant..."
                                  class="form-control"
                                  v-model="formChooseCheque2.montant"
                                  @input="v.formChooseCheque2.montant.$touch()"
                                />
                              </div>
                              <div class="input-errors" v-for="error of v.formChooseCheque2.montant.$errors" :key="error.$uid">
                                <div class="error-msg">{{ error.$message }}</div>
                              </div> 
                            </div>
                          </div>
                          <div class="col-sm-12">
                            <div class="form-group">
                              <label class="form-label">Date</label>
                              <div :class="v.formChooseCheque2.date.$errors.length==0?'input-group mb-15':'input-group mb-15 error-border'">
                                
                                <input
                                  type="date"
                                  placeholder="Date..."
                                  class="form-control"
                                  v-model="formChooseCheque2.date"
                                  @input="v.formChooseCheque2.date.$touch()"
                                />
                              </div>
                              <div class="input-errors" v-for="error of v.formChooseCheque2.date.$errors" :key="error.$uid">
                                <div class="error-msg">{{ error.$message }}</div>
                              </div> 
                            </div>
                          </div>
                        </div> 
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div class="modal-footer">
                <button
                  type="button"
                  class="btn btn-danger"
                  data-dismiss="modal"
                  @click="closeChooseChequeModal2"
                >
                  Close
                </button>
                <button
                  type="button"
                  class="waves-effect waves-light btn mb-5 btn-info" 
                  style="margin-left: 19px;"
                  @click="AddChequeModal"
                >
                  Ajouter chèque
                </button> 
                <button type="button" class="btn btn-primary float-end" id="addcentre" @click="ChooseCheque2">
                  Enregistrer
                </button>
              </div>
            </div>
          </div>
        </div>
        <!-- /.fin modal paiement reste a payer par cheque -->

        <!-- modal Ajouter cheque -->
        <div
          class="modal fade"
          id="AddChequeModal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Ajouter chèque </h5>
              </div>
              <div class="modal-body">
                <div class="col-12">
                  <div class="box">
                    <form class="form" @submit.prevent="submitForm">
                      <div class="box-body"> 
                        <div class="row"> 
                          <div class="col-sm-12">
                            <div class="form-group">
                              <label class="form-label">Banque</label>
                              <div :class="v.formAddCheque.banque.$errors.length==0?'input-group mb-15':'input-group mb-15 error-border'">
                                
                                <select class="form-select" v-model="formAddCheque.banque">
                                  <option :value="null" disabled>Choisie Banque</option> 
                                  <option
                                  :value="b.idbanque"
                                   v-for="b in Banques"
                                   :key="b.idbanque"
                                   >{{b.designation}}</option> 
                                </select>
                              </div>
                              <div class="input-errors" v-for="error of v.formAddCheque.banque.$errors" :key="error.$uid">
                                <div class="error-msg">{{ error.$message }}</div>
                              </div> 
                            </div>
                          </div>
                          <div class="col-sm-12">
                            <div class="form-group">
                              <label class="form-label">Proprietaire</label>
                              <div :class="v.formAddCheque.proprietaire.$errors.length==0?'input-group mb-15':'input-group mb-15 error-border'">
                                
                                <input
                                  type="text"
                                  placeholder="Proprietaire..."
                                  class="form-control"
                                  v-model="formAddCheque.proprietaire"
                                  @input="v.formAddCheque.proprietaire.$touch()"
                                />
                              </div>
                              <div class="input-errors" v-for="error of v.formAddCheque.proprietaire.$errors" :key="error.$uid">
                                <div class="error-msg">{{ error.$message }}</div>
                              </div> 
                            </div>
                          </div>
                          <div class="col-sm-12">
                            <div class="form-group">
                              <label class="form-label">Num chèque</label>
                              <div class="input-group mb-15">
                                <input
                                  type="text"
                                  placeholder="Numéro de chèque..."
                                  class="form-control"
                                  v-model="formAddCheque.num"
                                />
                              </div>
                            </div>
                          </div>
                          <div class="col-sm-12">
                            <div class="form-group">
                              <label class="form-label">Montant</label>
                              <div :class="v.formAddCheque.montant.$errors.length==0?'input-group mb-15':'input-group mb-15 error-border'">
                                
                                <input
                                  type="number"
                                  placeholder="Montant..."
                                  class="form-control"
                                  v-model="formAddCheque.montant"
                                  @input="v.formAddCheque.montant.$touch()"
                                />
                              </div>
                              <div class="input-errors" v-for="error of v.formAddCheque.montant.$errors" :key="error.$uid">
                                <div class="error-msg">{{ error.$message }}</div>
                              </div> 
                            </div>
                          </div>
                          <div class="col-sm-12">
                            <div class="form-group">
                              <label class="form-label">Echeance</label>
                              <div :class="v.formAddCheque.Echeance.$errors.length==0?'input-group mb-15':'input-group mb-15 error-border'">
                                
                                <input
                                  type="date"
                                  placeholder="Echeance..."
                                  class="form-control"
                                  v-model="formAddCheque.Echeance"
                                  @input="v.formAddCheque.Echeance.$touch()"
                                />
                              </div>
                              <div class="input-errors" v-for="error of v.formAddCheque.Echeance.$errors" :key="error.$uid">
                                <div class="error-msg">{{ error.$message }}</div>
                              </div> 
                            </div>
                          </div>
                          <div class="col-sm-12">
                            <div class="form-group" style="position:relative">
                              <input
                                type="checkbox"
                                style="left:0;width:18px;height:18px;z-index: 100;cursor:pointer;"
                                v-model="formAddCheque.garantie"
                                @input="v.formAddCheque.garantie.$touch()"
                              />
                              <label class="form-label">Garantie</label>
                                
                            </div>
                          </div>
                        </div> 
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div class="modal-footer">
                <button
                  type="button"
                  class="btn btn-danger"
                  data-dismiss="modal"
                  @click="closeAddChequeModal"
                >
                  Close
                </button>
                <button type="button" class="btn btn-primary float-end" id="addcentre" @click="AddCheque">
                  Enregistrer
                </button>
              </div>
            </div>
          </div>
        </div>
        <!-- /.fin modal ajouter cheque -->
        <!---------------------------------------------------------------------------------------------------------------------------------->
        <!-- modal Modifier groupe -->
        <div
          class="modal fade"
          id="UpdateGroupeModal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Modifier groupe : </h5>
              </div>
              <div class="modal-body">
                <div class="col-12">
                  <div class="box">
                    <form class="form" @submit.prevent="submitForm">
                      <div class="box-body"> 
                        <div class="row">
                          <div class="col-sm-12">
                            <div class="form-group">
                              <label class="form-label">Matiere</label>
                              <div :class="v.updatedGroupe.updatedMat.$errors.length==0?'input-group mb-15':'input-group mb-15 error-border'">
                                <select class="form-select"  @change="loadUpdateGroupes" v-model="updatedMat">
                                  <option :value="null">Choisie</option>
                                  <option
                                    v-for="m of Matieres"
                                  :value="m['idmatiere']"
                                    :key="m['idmatiere']"
                                    >{{m.designation}}</option> 
                                </select>
                              </div>
                              <div class="input-errors" v-for="error of v.updatedGroupe.updatedMat.$errors" :key="error.$uid">
                                <div class="error-msg">{{ error.$message }}</div>
                              </div> 
                            </div>
                          </div> 
                          <div class="col-sm-12">
                            <div class="form-group">
                              <label class="form-label">Cadence</label>
                              <div :class="v.updatedGroupe.updatedCad.$errors.length==0?'input-group mb-15':'input-group mb-15 error-border'">
                                <select class="form-select"  @change="loadUpdateGroupes" v-model="updatedCad">
                                  <option :value="null">Choisie</option>
                                  <option
                                    v-for="m of Cadences"
                                  :value="m['idcadence-hebdo']"
                                    :key="m['idcadence-hebdo']"
                                    >{{m['nbr-heures']}}</option> 
                                </select>
                              </div>
                              <div class="input-errors" v-for="error of v.updatedGroupe.updatedCad.$errors" :key="error.$uid">
                                <div class="error-msg">{{ error.$message }}</div>
                              </div> 
                            </div>
                          </div> 
                          <div class="col-sm-12">
                            <div class="form-group">
                              <div>
                                <label class="form-label">Periodes:</label>
                                
                                <Multiselect
                                  v-model="updatedPeriodes"
                                  :options="Periodes"
                                  valueProp="idperiode"
                                  label="designation"
                                  trackBy="designation"
                                  placeholder="Choisie Periodes"
                                  mode="tags"
                                  @change="ChangeUPeriodes"
                                />
                              </div>
                            </div>
                          </div>
                          <div class="col-sm-12">
                            <div class="form-group">
                              <div>
                                <label class="form-label">Date debut:</label>
                                <div class="input-group mb-15">
                                  <input
                                    type="date"
                                    class="form-control"
                                    v-model="UDebutDate"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-sm-12">
                            <div class="form-group">
                              <div>
                                <label class="form-label">Montant:</label>
                                <div :class="v.updatedGroupe.updatedMontant.$errors.length==0?'input-group mb-15':'input-group mb-15 error-border'">
                                  <input
                                    type="number"
                                    class="form-control"
                                    v-model="updatedMontant"
                                  />
                                </div>
                                <div class="input-errors" v-for="error of v.updatedGroupe.updatedMontant.$errors" :key="error.$uid">
                                  <div class="error-msg">{{ error.$message }}</div>
                                </div> 
                              </div>
                            </div>
                          </div>
                          <div class="col-sm-12">
                            <div class="form-group">
                              <label class="form-label">Groupe</label>
                              <div class="input-group mb-15">
                                <select class="form-select"  @change="selectGroupeHoraire2" v-model="updatedGroupe">
                                  <option :value="null">Non affecté</option>
                                  <option
                                    v-for="m of UGroupes"
                                  :value="m['idgroupe']"
                                    :key="m['idgroupe']"
                                    >{{m.niveau}}</option> 
                                </select>
                              </div>
                            </div>
                          </div>
                          <h4 >Horaires du groupe</h4> 
                          <div class="table-responsive">
                            <div
                              id="example_wrapper"
                              class="dataTables_wrapper container-fluid dt-bootstrap4 scrollable"
                            >
                              <table
                                id="Reste"
                                class="table table-bordered table-striped dataTable"
                                data-page-size="10"
                              >
                                <thead>
                                  <tr>
                                    <th>jour</th>
                                    <th>Horaire</th> 
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr v-for="(g,i) of GroupeHoraires2" :key="i">
                                    <td>{{weekDays[g.jour]}}</td>
                                    <td>{{"à "+g['heure-debut']+":"+g['min-debut']+" de "+g['heure-fin']+":"+g['min-fin']}}</td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div> 
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div class="modal-footer">
                <button
                  type="button"
                  class="btn btn-danger"
                  data-dismiss="modal"
                  @click="closeUpdateGroupeModal"
                >
                  Close
                </button>
                <button type="button" class="btn btn-primary float-end" id="addcentre" @click="updateGroupe">
                  Enregistrer
                </button>
              </div>
            </div>
          </div>
        </div>
        <!-- /.fin modal Modifier groupe -->

        <!-- modal Supprimer facture -->
        <div
          class="modal fade"
          id="deleteFactureModal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Supprimer facture</h5>
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  @click="closeDeleteFactureModal"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <h4>Voulez-vous vraiment supprimer cette facture?</h4>
                <div class="row">
                 <div class="col-12">
                  <div>
                    <label class="form-label">Client</label>
                    {{formI.idclient + " , " + formI.nom + " " + formI.prenom}}
                  </div>
                </div>  
                </div>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-danger" data-dismiss="modal" @click="closeDeleteFactureModal">
                  Non
                </button>
                <button type="button" class="btn btn-primary float-end" id="deletefacture" @click="deleteFacture">
                  Oui
                </button>
              </div>
            </div>
          </div>
        </div>
        <!-- /.fin modal Supprimer facture -->
  </div>
</template>

<script>
import { useVuelidate } from '@vuelidate/core';//
import { required, requiredIf } from '@vuelidate/validators';//
import "vue-loaders/dist/vue-loaders.css";
import VueLoadersBallBeat from "vue-loaders/dist/loaders/ball-beat";
import CategorieApi from "../api/categorie";
import factureApi from "../api/facture";
import conventionApi from "../api/convention";
import matiereApi from "../api/matiere";
import cadenceApi from "../api/cadence";
import groupeApi from "../api/groupe";
import anneeApi from "../api/annees";
import inscriptionApi from "../api/inscription";
import banqueApi from "../api/banque";
import chequeApi from "../api/cheque";
import CaisseApi from "../api/caisse";
import moment from "moment"
import UserApi from "../api/user";
import Multiselect from '@vueform/multiselect';

let $ = window.$;
let bootstrap = window.bootstrap;
export default {
  setup () {//
    return {  v: useVuelidate() }//
  },//
  components: {
    [VueLoadersBallBeat.component.name]: VueLoadersBallBeat.component,
    Multiselect,
  },
  props:['FI'],
  data() {
    return {
      weekDays:['lundi','mardi','mercredi','jeudi','vendredi','samedi','dimanche'],
      loader:false,
      loaderGroupes:false,
      AllGroupes:[],
      inscription: [],
      Periodesvalue:[],
      facture: {},
      tarif:0,
      tarif_frais:0,
      formuleId:null,
      paiement: [],
      reste_a_paye: [],
      total_paiement: 0,
      total_reste_a_paye: 0,
      true_total_reste_a_paye: 0,
      total_a_payer: 0,
      tarif_frais_inscription: 0,
      Categories:[],
      cheques:[],
      Conventions:[],  
      Matieres:[],  
      Cadences:[],  
      Periodes:[],
      Groupes:[],  
      UGroupes:[],
      GroupeHoraires:[],
      GroupeHoraires2:[],
      Utilisateurs:[],
      Banques:[],
      annee:null,
      updatedGroupe:null,
      updatedMat:null,
      updatedCad:null,
      updatedCon:null,
      UDebutDate:null,
      updatedMontant:null,
      updatedPeriodes:[],
      daysNbr:0,
      DebutDate:"",
      formI:{ 
        nom:'',
        prenom:'', 
        idclient:'' ,
        idfacture:0,
        CategorieId:null,
      },
      formInscription:{ 
        convention:null,
        matiere:null,
        cadence:null,
        periode:null,
        date:null,
        groupe:null,
        cat:null,
        NonAffecter:false,
        tarif:null,
        tarifManuel:false,
        tarifManuelValue:null,
      },
      formPeriodesA:{
        val:null,
      },
      formAddEspece:{
        montant:"",
        date:moment().format('YYYY-MM-DD'),
      },
      formAddEspece2:{
        montant:"",
        date:moment().format('YYYY-MM-DD'),
      },
      formAddResterAPayer:{
        montant:"",
        date:"",
      },
      formAddCheque:{
        banque:null,
        proprietaire:"",
        num:"",
        Echeance:"",
        montant:"",
        garantie:false,
      },
      formAddCarte:{
        banque:null,
        montant:"",
        date:moment().format('YYYY-MM-DD'),
      },
      formAddCarte2:{
        banque:null,
        montant:"",
        date:moment().format('YYYY-MM-DD'),
      },
      formAddVirement:{
        banque:null,
        montant:"",
        date:moment().format('YYYY-MM-DD'),
      },
      formAddVirement2:{
        banque:null,
        montant:"",
        date:moment().format('YYYY-MM-DD'),
      },
      formDPaiement:{
        id:null,
        type:"",
        montant:"",
        banque:"",
      },
      url:null,
      SelectedIdInscription:null,
      SelectedInscription:{},
      formChooseCheque:{
        cheque:null,
        montant:"",
        date:moment().format('YYYY-MM-DD'),
      },
      formChooseCheque2:{
        cheque:null,
        montant:"",
        date:moment().format('YYYY-MM-DD'),
      },
      selectedResterAPayer:{
        id:null,
        montant:'',
      },
      selectedId:null,
      formUpdateResteAPayer:{
        montant:0,date:"",id:null,
      },
      resterAPayerArray:[
        {montant:0,date:"",}
      ],
      today:moment().format('YYYY-MM-DD'),
      role:localStorage.getItem("role"),
    };
  },
  validations () {//
    return {
      updatedGroupe:{ 
        updatedMat:{ required },
        updatedCad:{ required },
        updatedCon:{ required },
        updatedMontant:{ required },
      },
      formChooseCheque:{
        cheque:{ required },
        montant:{ required },
        date:{ required },
      },
      formChooseCheque2:{
        cheque:{ required },
        montant:{ required },
        date:{ required },
      },
      formInscription:{ 
        convention:{ required },
        matiere:{ required },
        cadence:{ required },
        groupe:{
          required: requiredIf(function(){
            return this.formInscription.NonAffecter==false 
          })
        },
        tarif:{
          required: requiredIf(function(){
            return this.formInscription.NonAffecter==true 
          })
        },
        tarifManuelValue:{
          required: requiredIf(function(){
            return this.formInscription.tarifManuel==true 
          })
        },
        cat:{ required },
      },
      DebutDate:{ required },
      formPeriodesA:{
        val:{ required },
      },
      formAddEspece:{
        montant:{ required },
        date:{ required },
      },
      formAddEspece2:{
        montant:{ required },
        date:{ required },
      },
      formAddResterAPayer:{
        montant:{ required },
        date:{ required },
      },
      formAddCheque:{
        banque:{ required },
        proprietaire:{ required },
        Echeance:{ required },
        montant:{ required },
      },
      formAddCarte:{
        banque:{ required },
        date:{ required },
        montant:{ required },
      },
      formAddCarte2:{
        banque:{ required },
        date:{ required },
        montant:{ required },
      },
      formAddVirement:{
        banque:{ required },
        date:{ required },
        montant:{ required },
      },
      formAddVirement2:{
        banque:{ required },
        date:{ required },
        montant:{ required },
      },
    }
  },//
  beforeMount(){
    this.formI = this.FI;
  },
  mounted() {
    console.log('f')
    this.loadFactureData(this.formI.idfacture);
    $("#InscriptionModal").modal("show");
    groupeApi.getAll((r) => {
      this.AllGroupes=r.data;
    });
    this.loadCheques();
    CategorieApi.getAllCategories((r) => {
      this.Categories=r.data;
    });
    matiereApi.getAll((r) => {
      this.Matieres=r.data; 
    });
    cadenceApi.getAll((r) => {
      this.Cadences=r.data; 
    });
    conventionApi.getAll((r) => {
      if(r){
        this.Conventions=[]; 
        for(const c of r.data){
          if(c.centre == localStorage.getItem('centre') || c.centre == null){
            this.Conventions.push(c);
          }
        }
      }
    });
    banqueApi.getAll((r) => {
      this.Banques=r.data; 
    });
    UserApi.getAll((r) => {
      this.Utilisateurs=[];
      for(const u of r.data){
        if(u.administrateur == 4){
          this.Utilisateurs.push(u);
        }
      }
    })
    
    anneeApi.getAnneePeriodesByIdAnne({id:localStorage.getItem("annee")},(rr)=>{
      this.Periodes = [];
      for(const p of rr.data){
        if(moment(p.fin) > moment()){
          this.Periodes.push(p)
        }
      }
    })
  },
  methods: {
    loadCheques(){
      chequeApi.getAllWithRester((r) => {
        const dd = r.data.reverse()
        this.cheques = []; 
        for(const d of dd){
          if(d.centre_idcentre == localStorage.getItem('centre'))
            this.cheques.push({id:d.idcheque,label:d.proprietaire + " | "  + d.num_cheque  + " | " + d.montant +"Dhs | " + d.rester +"Dhs"});
        }
      });
    },
    ChangePeriodes(v){
      this.Periodesvalue = v;
      this.debutFInDate();
      this.selectGroupes();
    },
    ChangeUPeriodes(v){
      this.updatedPeriodes = v;
      this.UdebutFInDate();
      this.loadUpdateGroupes();
    },
    UdebutFInDate(){
      const debutDates = [];
      const finDates = [];
      let debut = "";
      const periodes = [];
      for(const p of this.Periodes){
        if(this.updatedPeriodes.map(v=>v.toString()).indexOf(p.idperiode.toString())!=-1){
          periodes.push(p);
        }
      }
      for(const a of periodes){
        debutDates.push(a.debut);
      }
      for(let i = 0 ; i < debutDates.length ; i++){
        if(i==0){
          debut = debutDates[i];
        }
        else if(moment(debutDates[i])<moment(debut)){
          debut = debutDates[i];
        }
      }
      if(moment()>moment(debut))
        this.UDebutDate = moment().format("YYYY-MM-DD");
      else
        this.UDebutDate = debut;
      this.updatedGroupe = null;
    },
    debutFInDate(){
      const debutDates = [];
      const finDates = [];
      let debut = "";
      let days = 0;
      const periodes = [];
      for(const p of this.Periodes){
        if(this.Periodesvalue.map(v=>v.toString()).indexOf(p.idperiode.toString())!=-1){
          periodes.push(p);
        }
      }
      for(const a of periodes){
        debutDates.push(a.debut);
        days += moment(a.fin).diff(moment(a.debut),"days");
      }
      this.daysNbr = days;
      for(let i = 0 ; i < debutDates.length ; i++){
        if(i==0){
          debut = debutDates[i];
        }
        else if(moment(debutDates[i])<moment(debut)){
          debut = debutDates[i];
        }
      }
      if(moment()>moment(debut))
        this.DebutDate = moment().format("YYYY-MM-DD");
      else
        this.DebutDate = debut;
      this.formInscription.groupe = null;
      this.calculer();
    },
    calculer(){
      if(this.formInscription.groupe!=null&&this.formInscription.convention!=null&&this.formInscription.matiere!=null&&this.formInscription.cadence!=null&&this.formInscription.cat!=null){
        $("#globalLoader").removeClass("H");
        $("#globalLoader").addClass("S");
        groupeApi.getGroupePeriodesByIdGroupe({id:this.formInscription.groupe},(res)=>{
          if(res){
            let nbrHeurs = 0;
            let heursPasser = 0;
            for(const d of res.data){
              if(moment(d.fin) < moment()){
                heursPasser += d['nbr-heures'];
              }
              if(this.Periodesvalue.map(a=>a.toString()).indexOf(d.idperiode.toString())!=-1){
                nbrHeurs += parseInt(d['nbr-heures']);
              }
            }
            console.log(nbrHeurs)
            inscriptionApi.getInscriptionTarifs(
              {
                idconvention:this.formInscription.convention,
                idcadence:this.formInscription.cadence,
                idmatiere:this.formInscription.matiere,
                idcategorie:this.formInscription.cat,
                idgroupe:this.formInscription.groupe,
                nbrHeurs:nbrHeurs,
              },
              (r) => {
                if(r){
                  console.log(r)
                  console.log('total heurs passer : ' , r.TotalHeurs)
                  const totalHeurs = parseInt(r.TotalHeurs);
                  const x = nbrHeurs - (totalHeurs-heursPasser);
                  const tarif = ((r.data?(parseInt(r.data.tarif)??0):0)*x)/nbrHeurs;
                  this.formuleId = r.data.idformule;
                  this.tarif= this.fixprix(tarif); 
                  this.tarif_frais=r.data?(parseInt(r.data['tarif-frais-inscription'])??0):0; 
                  $("#globalLoader").removeClass("S");
                  $("#globalLoader").addClass("H");
                }
              
            });
          }
        })
      }
    },
    fixprix(prix){
      const n = prix / 100;
      if(parseInt(n) == n){
        return prix;
      }
      else if ( parseInt(n) + 0.5 >= n ){
          return (parseInt(n) + 0.5) * 100;
      }
      else if( parseInt(n) + 0.5 < n ){
          return (parseInt(n) + 1) * 100;
      }
    },
    selectGroupes(){
      this.formInscription.groupe = null;
      this.tarif=0; 
      this.tarif_frais=0; 
      this.formuleId = null;
      this.calculer();
      this.Groupes = [];
      this.GroupeHoraires = [];
      if(this.formInscription.convention!=null&&this.formInscription.matiere!=null&&this.formInscription.cadence!=null&&this.Periodesvalue.length!=0){
        this.loaderGroupes = true;
        console.log({
            idmatiere:this.formInscription.matiere,
            idcadence:this.formInscription.cadence,
            idcategorie:this.formInscription.cat,
            idannee:localStorage.getItem("annee"),
          })
        groupeApi.getGroupesByMatCadAnn(
          {
            idmatiere:this.formInscription.matiere,
            idcadence:this.formInscription.cadence,
            idcategorie:this.formInscription.cat,
            idannee:localStorage.getItem("annee"),
          },
          async (r) => {
          this.Groupes=[]; 
          for(const g of r.data){
            if(g.centre_idcentre == localStorage.getItem('centre')){
              await groupeApi.getGroupePeriodesByIdGroupe({id:g.idgroupe},(rr)=>{
                if(rr){
                  let verify = true;
                  for(const a of this.Periodesvalue){
                    if(rr.data.map(p=>p.idperiode.toString()).indexOf(a.toString())==-1){
                      verify = false;
                    }
                  }
                  if(verify){
                    this.Groupes.push(g);
                  }
                }
              })
            }
          }
          this.loaderGroupes = false;
        });
      }
    },
    selectGroupeHoraire(){
      this.calculer();
      this.GroupeHoraires = [];
      if(this.formInscription.groupe!=null){
        groupeApi.getGroupeHorairesByIdGroupe(
          {
            id:this.formInscription.groupe,
          },
          (r) => {
          this.GroupeHoraires=r.data; 
        });
      }
    },
    selectGroupeHoraire2(){
      this.GroupeHoraires2 = [];
      if(this.updatedGroupe!=null){
        groupeApi.getGroupeHorairesByIdGroupe(
          {
            id:this.updatedGroupe,
          },
          (r) => {
          this.GroupeHoraires2=r.data; 
        });
      }
    },
    ChooseCheque(){
      if(this.v.formChooseCheque.$invalid){
        this.v.formChooseCheque.$touch();
      }
      else{
        if(parseInt(this.true_total_reste_a_paye) - parseInt(this.formChooseCheque.montant) < 0){
          console.log("il faut choisir montant mois que : " + this.true_total_reste_a_paye + "dh" )
        }
        else{
          factureApi.createPaiementCheque({
            date:this.formChooseCheque.date,
            montant:this.formChooseCheque.montant,
            utilisateur_idutilisateur:localStorage.getItem("id"),
            facture_idfacture:this.formI.idfacture,
            cheque_idcheque:this.formChooseCheque.cheque,
          },async(r)=>{
            if(r){
              this.loadFactureData(this.formI.idfacture);
              this.loadCheques();
              this.ClearModal();
              this.closeChooseChequeModal();
            }
            else{
              console.log("error",r.msg)
            }
          })
        }
      }
    },
    ChooseCheque2(){
      if(this.v.formChooseCheque2.$invalid){
        this.v.formChooseCheque2.$touch();
      }
      else{
        $("#globalLoader").removeClass("H");
        $("#globalLoader").addClass("S");
        factureApi.deleteResteAPayer({
          id:this.selectedResterAPayer.id,
        },(r) => {
          if(r!=null)
          {
            if(r.success==true){
              factureApi.createPaiementCheque({
                date:this.formChooseCheque2.date,
                montant:this.formChooseCheque2.montant,
                utilisateur_idutilisateur:localStorage.getItem("id"),
                facture_idfacture:this.formI.idfacture,
                cheque_idcheque:this.formChooseCheque2.cheque,
              },async(r)=>{
                if(r){
                  this.loadFactureData(this.formI.idfacture);
                  this.loadCheques();
                  this.ClearModal();
                  this.closeChooseChequeModal2();
                  this.closePayerResteAPayer();
                  $("#globalLoader").removeClass("S");
                  $("#globalLoader").addClass("H");
                }
                else{
                  console.log("error",r.msg)
                }
              })
            }
            else{
              console.log("error",r.msg)
            }
          }
        });
      }
    },
    async loadFactureData(id){
      this.paiement = [];
      if(id!=0){
        this.inscription = [];
        this.facture = {};
        this.reste_a_paye = [];
        this.total_paiement = 0;
        this.total_reste_a_paye = 0;
        this.true_total_reste_a_paye = 0;
        this.tarif_frais_inscription = 0;
        this.total_a_payer = 0;
        console.log(id)
        await factureApi.getOneAllDataFacture({id:id},(r) => {
          if(r!=null){
            if(r.success==true){
              console.log(r.data)
              this.inscription = r.data.inscription;
              this.facture = r.data.facture;
              this.reste_a_paye = r.data.reste_a_paye;
              this.total_paiement = parseInt(r.data.total_paiement??0);
              this.tarif_frais_inscription = parseInt(r.data.tarif_frais_inscription??0);
              this.total_a_payer = parseInt(r.data.total_a_payer??0);
              this.total_reste_a_paye = (this.total_a_payer + this.tarif_frais_inscription) - (this.total_paiement + parseInt(r.data.total_reste_a_paye??0) + parseInt(this.facture.remise??0) ) ;
              this.true_total_reste_a_paye = (this.total_a_payer + this.tarif_frais_inscription) - (this.total_paiement + parseInt(this.facture.remise??0) ) ;
              this.paiement = [];
              for (const p of r.data.paiement_cheque) {
                this.paiement.push({
                  id:p['idpaiement-cheque'],
                  montant:p.montant,
                  date:p.date,
                  type:"chèque",
                  banque:"",
                  email:p.email,
                })
              }
              for (const p of r.data.paiement_espece) {
                this.paiement.push({
                  id:p['idpaiement-espece'],
                  montant:p.montant,
                  date:p.date,
                  type:"Espèces",
                  banque:"",
                  email:p.email,
                })
              }
              for (const p of r.data.paiement_carte) {
                this.paiement.push({
                  id:p['idpaiement_carte'],
                  montant:p.montant,
                  date:p.date,
                  type:"carte bancaire",
                  banque:p.compte,
                  email:p.email,
                })
              }
              for (const p of r.data.paiement_virement) {
                this.paiement.push({
                  id:p['idpaiement_virement'],
                  montant:p.montant,
                  date:p.date,
                  type:"virement bancaire",
                  banque:p.compte,
                  email:p.email,
                })
              }
            }
          }
          
        });
      }
    },
    addInscription(){
      if(this.v.formInscription.$invalid){
        this.v.formInscription.$touch();
      }
      else{
        
        $("#globalLoader").removeClass("H");
        $("#globalLoader").addClass("S");
        inscriptionApi.getInscriptionFormule(
          {
            idconvention:this.formInscription.convention,
            idcadence:this.formInscription.cadence,
            idmatiere:this.formInscription.matiere,
            idcategorie:this.formInscription.cat,
          },
          (r) => {
            if(r){
              if(r.data){
                inscriptionApi.addInscription({
                  date:this.DebutDate,
                  matiere:this.formInscription.matiere,
                  cadence:this.formInscription.cadence,
                  formule:this.formInscription.NonAffecter==true?r.data.idformule:this.formuleId,
                  groupe:this.formInscription.NonAffecter==true?null:this.formInscription.groupe,
                  facture:this.formI.idfacture,
                  montant:this.formInscription.NonAffecter==true?this.formInscription.tarif:(this.formInscription.tarifManuel?this.formInscription.tarifManuelValue:this.tarif),
                  frais:this.formInscription.NonAffecter==true?(parseInt(r.data['tarif-frais-inscription'])??0):this.tarif_frais,
                },async(r) => {
                  console.log(r)
                  if(r!=null)
                  {
                    if(r.success==true){
                      for(const p of this.Periodesvalue){
                        await inscriptionApi.addInscriptionPeriode({idinscription:r.id,idperiode:p},(rr)=>{
                          if(rr){
                            console.log("periode",rr);
                          }
                        })
                      }
                      this.loadFactureData(this.formI.idfacture);
                      this.ClearModal();
                      this.closeModalNewInscription();
                      $("#globalLoader").removeClass("S");
                      $("#globalLoader").addClass("H");
                    }
                    else{
                      console.log("error",r.msg)
                    }
                  }
                });
              }
            }
          
        });
      }
    },
    updateGroupe(){
      if(this.updatedMat != null && this.updatedCad != null && this.UDebutDate != null && this.updatedPeriodes.length != 0){
        inscriptionApi.updateInscriptionGroupe({
          id:this.SelectedInscription.idinscription,
          idgroupe:this.updatedGroupe,
          idmatiere:this.updatedMat,
          idcadence:this.updatedCad,
          date:this.UDebutDate,
          montant:this.updatedMontant,
        },async(r) => {
          if(r!=null)
          {
            if(r.success==true){
              for(const p of this.updatedPeriodes){
                await inscriptionApi.addInscriptionPeriode({idinscription:this.SelectedInscription.idinscription,idperiode:p},(rr)=>{
                  if(rr){
                    console.log("periode",rr);
                  }
                })
              }
              this.loadFactureData(this.formI.idfacture);
              this.ClearModal();
              this.closeUpdateGroupeModal();
            }
            else{
              console.log("error",r.msg)
            }
          }
        });
      }
    },
    deleteInscription(){
        inscriptionApi.deleteInscription({
          id:this.SelectedIdInscription,
        },(r) => {
          if(r!=null)
          {
            if(r.success==true){
              this.loadFactureData(this.formI.idfacture);
              this.ClearModal();
              this.closeModalDeleteInsc();
              
            }
            else{
              console.log("error",r.msg)
            }
          }
        });
    },
    AddEscpece2(){
      if(this.v.formAddEspece2.$invalid){
        this.v.formAddEspece2.$touch();
      }
      else{
        $("#globalLoader").removeClass("H");
        $("#globalLoader").addClass("S");
        factureApi.deleteResteAPayer({
          id:this.selectedResterAPayer.id,
        },(r) => {
          if(r!=null)
          {
            if(r.success==true){
              factureApi.createPaiementEspece({
                date:this.formAddEspece2.date,
                montant:this.formAddEspece2.montant,
                utilisateur_idutilisateur:localStorage.getItem("id"),
                facture_idfacture:this.formI.idfacture,
              },async(r) => {
                if(r!=null)
                {
                  if(r.success==true){
                    await CaisseApi.add({
                      recette:true,   
                      libelle:"Encaissement F."+this.formI.idfacture+", " + this.formI.nom + " " + this.formI.prenom,   
                      montant:this.formAddEspece2.montant,   
                      utilisateur_idutilisateur:localStorage.getItem("id"),   
                      centre:localStorage.getItem("centre"),   
                    },(rr) => {
                      if(rr!=null)
                      {
                        if(rr.success==true){
                          this.loadFactureData(this.formI.idfacture);
                          this.ClearModal();
                          this.closeAddEscpeceModal2();
                          this.closePayerResteAPayer();
                          $("#globalLoader").removeClass("S");
                          $("#globalLoader").addClass("H");
                        }
                      }
                    });
                  }
                  else{
                    console.log("error",r.msg)
                  }
                }
              });
              
            }
            else{
              console.log("error",r.msg)
            }
          }
        });
      }
    },
    AddEscpece(){
      if(this.v.formAddEspece.$invalid){
        this.v.formAddEspece.$touch();
      }
      else{
        if(parseInt(this.true_total_reste_a_paye) - parseInt(this.formAddEspece.montant) < 0){
          console.log("il faut choisir montant mois que : " + this.true_total_reste_a_paye + "dh" )
        }
        else{
          factureApi.createPaiementEspece({
            date:this.formAddEspece.date,
            montant:this.formAddEspece.montant,
            utilisateur_idutilisateur:localStorage.getItem("id"),
            facture_idfacture:this.formI.idfacture,
          },async(r)=>{
            if(r){
              await CaisseApi.add({
                recette:true,   
                libelle:"Encaissement F."+this.formI.idfacture+", " + this.formI.nom + " " + this.formI.prenom,   
                montant:this.formAddEspece.montant,   
                utilisateur_idutilisateur:localStorage.getItem("id"),  
                centre:localStorage.getItem("centre"),    
              },(rr) => {
                if(rr!=null)
                {
                  if(rr.success==true){
                    this.loadFactureData(this.formI.idfacture);
                    this.ClearModal();
                    this.closeAddEscpeceModal();
                  }
                }
              });
            }
            else{
              console.log("error",r.msg)
            }
          })
        }
      }
    },
    AddVirement(){
      if(this.v.formAddVirement.$invalid){
        this.v.formAddVirement.$touch();
      }
      else{
        if(parseInt(this.true_total_reste_a_paye) - parseInt(this.formAddVirement.montant) < 0){
          console.log("il faut choisir montant mois que : " + this.true_total_reste_a_paye + "dh" )
        }
        else{
          factureApi.createPaiementVirement({
            date:this.formAddVirement.date,
            montant:this.formAddVirement.montant,
            compte:this.formAddVirement.banque,
            utilisateur_idutilisateur:localStorage.getItem("id"),
            facture_idfacture:this.formI.idfacture,
          },async(r)=>{
            await CaisseApi.add({
              recette:true,   
              libelle:"Paiement par virement bancaire F."+this.formI.idfacture+", " + this.formI.nom + " " + this.formI.prenom,   
              montant:this.formAddVirement.montant,   
              utilisateur_idutilisateur:this.formAddVirement.banque,   
              centre:localStorage.getItem("centre"),   
            },(rr) => {
              if(rr!=null)
              {
                if(rr.success==true){
                  this.loadFactureData(this.formI.idfacture);
                  this.ClearModal();
                  this.closeAddVirementModal();
                }
              }
            });
          })
        }
      }
    },
    AddVirement2(){
      if(this.v.formAddVirement2.$invalid){
        this.v.formAddVirement2.$touch();
      }
      else{
        $("#globalLoader").removeClass("H");
        $("#globalLoader").addClass("S");
        factureApi.deleteResteAPayer({
          id:this.selectedResterAPayer.id,
        },(r) => {
          if(r!=null)
          {
            if(r.success==true){
              factureApi.createPaiementVirement({
                date:this.formAddVirement2.date,
                montant:this.formAddVirement2.montant,
                compte:this.formAddVirement2.banque,
                utilisateur_idutilisateur:localStorage.getItem("id"),
                facture_idfacture:this.formI.idfacture,
              },async(r)=>{
                await CaisseApi.add({
                  recette:true,   
                  libelle:"Paiement par virement bancaire F."+this.formI.idfacture+", " + this.formI.nom + " " + this.formI.prenom,   
                  montant:this.formAddVirement2.montant,   
                  utilisateur_idutilisateur:this.formAddVirement2.banque,   
                  centre:localStorage.getItem("centre"),   
                },(rr) => {
                  if(rr!=null)
                  {
                    if(rr.success==true){
                      this.loadFactureData(this.formI.idfacture);
                      this.ClearModal();
                      this.closeAddVirementModal2();
                      this.closePayerResteAPayer();
                      $("#globalLoader").removeClass("S");
                      $("#globalLoader").addClass("H");
                    }
                  }
                });
              })
            }
            else{
              console.log("error",r.msg)
            }
          }
        });
      }
    },
    AddCarte(){
      if(this.v.formAddCarte.$invalid){
        this.v.formAddCarte.$touch();
      }
      else{
        if(parseInt(this.true_total_reste_a_paye) - parseInt(this.formAddCarte.montant) < 0){
          console.log("il faut choisir montant mois que : " + this.true_total_reste_a_paye + "dh" )
        }
        else{
          factureApi.createPaiementCarte({
            date:this.formAddCarte.date,
            montant:this.formAddCarte.montant,
            compte:this.formAddCarte.banque,
            utilisateur_idutilisateur:localStorage.getItem("id"),
            facture_idfacture:this.formI.idfacture,
          },async(r)=>{
            await CaisseApi.add({
              recette:true,   
              libelle:"Paiement par carte bancaire F."+this.formI.idfacture+", " + this.formI.nom + " " + this.formI.prenom,   
              montant:this.formAddCarte.montant,   
              utilisateur_idutilisateur:this.formAddCarte.banque,   
              centre:localStorage.getItem("centre"),   
            },(rr) => {
              if(rr!=null)
              {
                if(rr.success==true){
                  this.loadFactureData(this.formI.idfacture);
                  this.ClearModal();
                  this.closeAddCarteModal();
                }
              }
            });
          })
        }
      }
    },
    AddCarte2(){
      if(this.v.formAddCarte2.$invalid){
        this.v.formAddCarte2.$touch();
      }
      else{
        $("#globalLoader").removeClass("H");
        $("#globalLoader").addClass("S");
        factureApi.deleteResteAPayer({
          id:this.selectedResterAPayer.id,
        },(r) => {
          if(r!=null)
          {
            if(r.success==true){
              factureApi.createPaiementCarte({
                date:this.formAddCarte2.date,
                montant:this.formAddCarte2.montant,
                compte:this.formAddCarte2.banque,
                utilisateur_idutilisateur:localStorage.getItem("id"),
                facture_idfacture:this.formI.idfacture,
              },async(r)=>{
                await CaisseApi.add({
                  recette:true,   
                  libelle:"Paiement par carte bancaire F."+this.formI.idfacture+", " + this.formI.nom + " " + this.formI.prenom,   
                  montant:this.formAddCarte2.montant,   
                  utilisateur_idutilisateur:this.formAddCarte2.banque,   
                  centre:localStorage.getItem("centre"),   
                },(rr) => {
                  if(rr!=null)
                  {
                    if(rr.success==true){
                      this.loadFactureData(this.formI.idfacture);
                      this.ClearModal();
                      this.closeAddCarteModal2();
                      this.closePayerResteAPayer();
                      $("#globalLoader").removeClass("S");
                      $("#globalLoader").addClass("H");
                    }
                  }
                });
              })
            }
            else{
              console.log("error",r.msg)
            }
          }
        });
      }
    },
    AddCheque(){
      if(this.v.formAddCheque.$invalid){
        this.v.formAddCheque.$touch();
      }
      else{
        chequeApi.add({
          proprietaire:this.formAddCheque.proprietaire + "(" + this.formI.nom + " " + this.formI.prenom +")",
          num:this.formAddCheque.num,
          montant:this.formAddCheque.montant,
          garantie:this.formAddCheque.garantie,
          echeance:this.formAddCheque.Echeance,
          idbanque:this.formAddCheque.banque,
          centre_idcentre:localStorage.getItem('centre'),
        },async(r)=>{
          if(r){
            this.loadCheques();
            this.closeAddChequeModal();
          }
          else{
            console.log("error",r.msg)
          }
        })
      }
    },
    deletePaiement(){
      if(this.formDPaiement.type=="Espèces"){
        factureApi.deletePaiementEspece({
          id:this.formDPaiement.id,
        },async(r) => {
          if(r!=null)
          {
            if(r.success==true){
              await CaisseApi.add({
                recette:false,   
                libelle:"Annulation l'encaissement F."+this.formI.idfacture+", " + this.formI.nom + " " + this.formI.prenom,   
                montant:this.formDPaiement.montant,   
                utilisateur_idutilisateur:localStorage.getItem("id"),
                centre:localStorage.getItem("centre"),      
              },(rr) => {
                if(rr!=null)
                {
                  if(rr.success==true){
                    this.loadFactureData(this.formI.idfacture);
                    this.ClearModal();
                    this.closeDeletePaiementModal();
                  }
                }
              });
            }
            else{
              console.log("error",r.msg)
            }
          }
        });
      }
      else if(this.formDPaiement.type=="chèque"){
        factureApi.deletePaiementCheque({
          id:this.formDPaiement.id,
        },(r) => {
          if(r!=null)
          {
            if(r.success==true){
              this.loadFactureData(this.formI.idfacture);
              this.ClearModal();
              this.closeDeletePaiementModal();
            }
            else{
              console.log("error",r.msg)
            }
          }
        });
      }
      else if(this.formDPaiement.type=="carte bancaire"){
        factureApi.deletePaiementCarte({
          id:this.formDPaiement.id,
        },async(r) => {
          if(r!=null)
          {
            if(r.success==true){
              await CaisseApi.add({
                recette:false,   
                libelle:"Annulation de Paiement par carte bancaire F."+this.formI.idfacture+", " + this.formI.nom + " " + this.formI.prenom,   
                montant:this.formDPaiement.montant,   
                utilisateur_idutilisateur:this.formDPaiement.banque,   
                centre:localStorage.getItem("centre"),
              },(rr) => {
                if(rr!=null)
                {
                  if(rr.success==true){
                    this.loadFactureData(this.formI.idfacture);
                    this.ClearModal();
                    this.closeDeletePaiementModal();
                  }
                }
              });
            }
            else{
              console.log("error",r.msg)
            }
          }
        });
      }
      else if(this.formDPaiement.type=="virement bancaire"){
        factureApi.deletePaiementVirement({
          id:this.formDPaiement.id,
        },async(r) => {
          if(r!=null)
          {
            if(r.success==true){
              await CaisseApi.add({
                recette:false,   
                libelle:"Annulation de Paiement par virement bancaire F."+this.formI.idfacture+", " + this.formI.nom + " " + this.formI.prenom,   
                montant:this.formDPaiement.montant,   
                utilisateur_idutilisateur:this.formDPaiement.banque,   
                centre:localStorage.getItem("centre"),
              },(rr) => {
                if(rr!=null)
                {
                  if(rr.success==true){
                    this.loadFactureData(this.formI.idfacture);
                    this.ClearModal();
                    this.closeDeletePaiementModal();
                  }
                }
              });
            }
            else{
              console.log("error",r.msg)
            }
          }
        });
      }
      else if(this.formDPaiement.type=="aPayer"){
        factureApi.deleteResteAPayer({
          id:this.formDPaiement.id,
        },(r) => {
          if(r!=null)
          {
            if(r.success==true){
              this.loadFactureData(this.formI.idfacture);
              this.ClearModal();
              this.closeDeletePaiementModal();
              
            }
            else{
              console.log("error",r.msg)
            }
          }
        });
      }
        
    },
    UpdateResterAPayer(){
      $("#globalLoader").removeClass("H");
      $("#globalLoader").addClass("S");
      factureApi.updateResteAPayer({
        date:this.formUpdateResteAPayer.date,
        montant:this.formUpdateResteAPayer.montant,
        id:this.formUpdateResteAPayer.id,
      },(r)=>{
        if(r){
          this.loadFactureData(this.formI.idfacture);
          this.ClearModal();
          this.closeUpdateResterAPayerModal();
          $("#globalLoader").removeClass("S");
          $("#globalLoader").addClass("H");
        }
        else{
          console.log("error",r.msg)
        }
      })
      
      
    },
    AddResterAPayer(){
      var verify = true;
      var verify2 = true;
      for(const r of this.resterAPayerArray){
        if(moment(r.date)<moment()){
          verify2 = false;
        }
      }
      for(const r of this.resterAPayerArray){
        if(!r.montant){
          verify = false
        }
      }
      if(verify==false){
        alert("Il y a des champs vides!");
      }
      else if(verify2==false){
        alert("Il y a des dates invalid!");
      }
      else{
        $("#globalLoader").removeClass("H");
        $("#globalLoader").addClass("S");
        for(let i = 0 ; i < this.resterAPayerArray.length ; i++){
          factureApi.createResteAPayer({
            date:this.resterAPayerArray[i].date,
            montant:this.resterAPayerArray[i].montant,
            facture_idfacture:this.formI.idfacture,
          },(r)=>{
            if(r){
              if(i+1 == this.resterAPayerArray.length){
                this.loadFactureData(this.formI.idfacture);
                this.ClearModal();
                this.closeAddResterAPayerModal();
                $("#globalLoader").removeClass("S");
                $("#globalLoader").addClass("H");
              }
            }
            else{
              console.log("error",r.msg)
            }
          })
        }
      }
    },
    AddRemise(){
        console.log({
          id:this.facture.idfacture,
          date:this.facture.date,
          client_idclient:this.facture.client_idclient,
          utilisateur_idutilisateur:this.facture.utilisateur_idutilisateur,
          remise:this.facture.remise,
          motif_remise:this.facture['motif-remise'],
          creance_acquise:this.facture['creance-acquise'],
        })
        factureApi.update({
          id:this.facture.idfacture,
          date:this.facture.date,
          client_idclient:this.facture.client_idclient,
          utilisateur_idutilisateur:this.facture.utilisateur_idutilisateur,
          remise:this.facture.remise,
          motif_remise:this.facture['motif-remise'],
          creance_acquise:this.facture['creance-acquise'],
        },(r) => {
          if(r!=null)
          {
            if(r.success==true){
              this.loadFactureData(this.formI.idfacture);
              
            }
            else{
              console.log("error",r.msg)
            }
          }
        });
    },
    deleteFactureModal(){
      $("#deleteFactureModal").modal("show");
    },
    closeDeleteFactureModal(){
      $("#deleteFactureModal").modal("hide");
    },
    deleteFacture(){
      factureApi.delete({
        id:this.formI.idfacture, 
       },(r) => {
         if(r!=null)
         {
           if(r.success==true){
            this.loadFactureData(this.formI.idfacture);
            this.closeDeleteFactureModal();
           }
         }
      });
    },
    imprimer(){
      const inscritions = [];
      let index = 0;
      let ok = false;
      for(const i of this.inscription){
        if(i.groupe_idgroupe!=null){
          groupeApi.getGroupeHorairesByIdGroupe(
            {
              id:i.groupe_idgroupe,
            },
            (r) => {
            let horaire = "";
            for(const g of r.data){
              horaire += "-" + this.weekDays[g.jour] + " à " + g['heure-debut']+":"+g['min-debut']+" de "+g['heure-fin']+":"+g['min-fin'] + " ";
            }
            inscritions.push({
              matiere:i.matiere,
              date:i.date,
              montant:i.montant,
              nbr_heures:i['nbr-heures'],
              horaire:horaire,
            }); 
            index++;
            if(this.inscription.length == index){
              ok=true;
              const ins = JSON.stringify(inscritions);
              const reste_a_paye = JSON.stringify(this.reste_a_paye);
              const paiement = JSON.stringify(this.paiement);
              const data = {
                idfacture:this.formI.idfacture,
                idclient:this.formI.idclient,
                nom:this.formI.nom + ' ' + this.formI.prenom,
                paiement:paiement,
                inscription:ins,
                reste_a_paye:reste_a_paye,
                frais_inscription:this.tarif_frais_inscription,
                Total: this.total_a_payer + this.tarif_frais_inscription,
                remise: this.facture.remise,
                total_payer: this.total_paiement,
                total_reste_a_paye: this.true_total_reste_a_paye,
                centre:localStorage.getItem('centreName'),
              };
              this.closeModalInscription();
              this.$router.push({ name: "Recu" , params: data });
            }
          });
        }
        else{
          index++;
          inscritions.push({
            matiere:i.matiere,
            date:i.date,
            montant:i.montant,
            nbr_heures:i['nbr-heures'],
            horaire:"",
          }); 
          if(this.inscription.length == index){
            ok=true;
            const ins = JSON.stringify(inscritions);
            const reste_a_paye = JSON.stringify(this.reste_a_paye);
            const paiement = JSON.stringify(this.paiement);
            const data = {
              idfacture:this.formI.idfacture,
              idclient:this.formI.idclient,
              nom:this.formI.nom + ' ' + this.formI.prenom,
              paiement:paiement,
              inscription:ins,
              reste_a_paye:reste_a_paye,
              frais_inscription:this.tarif_frais_inscription,
              Total: this.total_a_payer + this.tarif_frais_inscription,
              remise: this.facture.remise,
              total_payer: this.total_paiement,
              total_reste_a_paye: this.true_total_reste_a_paye,
              centre:localStorage.getItem('centreName'),
            };
            this.closeModalInscription();
            this.$router.push({ name: "Recu" , params: data });
          }
        }
        
      }
    },
    NewInscriptionModal() {
      this.formInscription.convention = this.Conventions.map(v=>parseInt(v["par-defaut"])).indexOf(1)!=-1?this.Conventions[this.Conventions.map(v=>parseInt(v["par-defaut"])).indexOf(1)].idconvention:null;
      this.formInscription.matiere = null;
      this.formInscription.groupe = null;
      this.formInscription.cadence = null;
      this.formInscription.cat = this.formI.CategorieId;
      this.formInscription.NonAffecter = false;
      this.formInscription.tarif = 0;
      this.formInscription.tarifManuel = false;
      this.formInscription.tarifManuelValue = 0;
      this.Periodesvalue = [];
      this.DebutDate="";
      this.GroupeHoraires=[];
      this.daysNbr = 0;
      this.tarif=0; 
      this.tarif_frais=0;
      this.formuleId = null;
      $("#NewInscriptionModal").modal("show");
    }, 
    DeleteInscModal(id) {
      this.SelectedIdInscription = id;
      $("#DeleteInscModal").modal("show");
    },
    closeModalDeleteInsc() {
      $("#DeleteInscModal").modal("hide");
    },
    UpdateGroupeModal(insc) {
      console.log(insc)
      this.updatedGroupe = null;
      this.updatedMat = null;
      this.updatedCad = null;
      this.updatedCon = null;
      this.UDebutDate = null;
      this.updatedPeriodes = [];
      this.SelectedInscription = insc;
      this.updatedGroupe = insc.idgroupe;
      this.updatedMat = insc.idmatiere;
      this.updatedCad = insc['idcadence-hebdo'];
      this.updatedCon = insc.idconvention;
      this.UDebutDate = insc.date;
      this.updatedMontant = insc.montant;
      this.UGroupes=[];
      this.GroupeHoraires2 = [];
      inscriptionApi.getAllPeriodesByInscription({id:this.SelectedInscription.idinscription},async(r)=>{
        if(r){
          this.updatedPeriodes = [];
          for(const a of r.data){
            this.updatedPeriodes.push(a.periode_idperiode);
          }
        }
      })
      this.loadUpdateGroupes();
      $("#UpdateGroupeModal").modal("show");
    },
    loadUpdateGroupes() {
      $("#globalLoader").removeClass("H");
      $("#globalLoader").addClass("S");
      groupeApi.getGroupesByMatCadAnn(
        {
          idmatiere:this.updatedMat,
          idcadence:this.updatedCad,
          idcategorie:this.formI.CategorieId,
          idannee:localStorage.getItem("annee"),
        },
        async (r) => {
        this.UGroupes=[]; 
        for(const g of r.data){
          if(g.centre_idcentre == localStorage.getItem('centre')){
            await groupeApi.getGroupePeriodesByIdGroupe({id:g.idgroupe},async(rr)=>{
              if(rr){
                await inscriptionApi.getAllPeriodesByInscription({id:this.SelectedInscription.idinscription},async(rrr)=>{
                  if(rrr){
                    let verify = true;
                    for(const a of rrr.data){
                      if(rr.data.map(p=>p.idperiode.toString()).indexOf(a.periode_idperiode.toString())==-1){
                        verify = false;
                      }
                    }
                    if(verify){
                      this.UGroupes.push(g);
                      $("#globalLoader").removeClass("S");
                      $("#globalLoader").addClass("H");
                      this.selectGroupeHoraire2();
                    }
                  }
                })
              }
            })
          }
        }
      });
    },
    closeUpdateGroupeModal() {
      $("#UpdateGroupeModal").modal("hide");
    },
    PayerResteAPayer(id,montant) {
      this.selectedResterAPayer.id = id;
      this.selectedResterAPayer.montant = montant;
      $("#PayerResteAPayer").modal("show");
    },
    closePayerResteAPayer() {
      $("#PayerResteAPayer").modal("hide");
    },
    DeletePaiementModal(id,type,montant,banque) {
      this.formDPaiement.id = id;
      this.formDPaiement.type = type;
      this.formDPaiement.montant = montant;
      this.formDPaiement.banque = banque;
      $("#DeletePaiementModal").modal("show");
    },
    closeDeletePaiementModal() {
      $("#DeletePaiementModal").modal("hide");
    },
    AddEscpeceModal() {
      this.formAddEspece.montant = '';
      this.formAddEspece.date = moment().format('YYYY-MM-DD');
      $("#AddEscpeceModal").modal("show");
    },
    closeAddEscpeceModal() {
      $("#AddEscpeceModal").modal("hide");
    },
    AddEscpeceModal2() {
      this.formAddEspece2.montant = this.selectedResterAPayer.montant;
      this.formAddEspece2.date = moment().format('YYYY-MM-DD');
      $("#AddEscpeceModal2").modal("show");
    },
    closeAddEscpeceModal2() {
      $("#AddEscpeceModal2").modal("hide");
    },
    AddResterAPayerModal() {
      this.resterAPayerArray = [{montant:0,date:""}]
      $("#AddResterAPayerModal").modal("show");
    },
    closeAddResterAPayerModal() {
      $("#AddResterAPayerModal").modal("hide");
    },
    UpdateResterAPayerModal(d) {
      this.formUpdateResteAPayer.id = d['idreste-a-payer'];
      this.formUpdateResteAPayer.montant = d.montant;
      this.formUpdateResteAPayer.date = d.date;
      $("#UpdateResterAPayerModal").modal("show");
    },
    closeUpdateResterAPayerModal() {
      $("#UpdateResterAPayerModal").modal("hide");
    },
    AddChequeModal() {
      this.formAddCheque.montant = '';
      this.formAddCheque.garantie = false;
      this.formAddCheque.proprietaire = '';
      this.formAddCheque.Echeance = '';
      this.formAddCheque.banque = null;
      $("#AddChequeModal").modal("show");
    },
    closeAddChequeModal() {
      $("#AddChequeModal").modal("hide");
    },
    AddCarteModal() {
      this.formAddCarte.montant = '';
      this.formAddCarte.date = moment().format('YYYY-MM-DD');
      this.formAddCarte.banque = null;
      $("#AddCarteModal").modal("show");
    },
    closeAddCarteModal() {
      $("#AddCarteModal").modal("hide");
    },
    AddCarteModal2() {
      this.formAddCarte2.montant = this.selectedResterAPayer.montant;
      this.formAddCarte2.date = moment().format('YYYY-MM-DD');
      this.formAddCarte2.banque = null;
      $("#AddCarteModal2").modal("show");
    },
    closeAddCarteModal2() {
      $("#AddCarteModal2").modal("hide");
    },
    ChooseChequeModal() {
      this.formChooseCheque.montant = '';
      this.formChooseCheque.date = moment().format('YYYY-MM-DD');
      this.formChooseCheque.cheque = null;
      $("#ChooseChequeModal").modal("show");
    },
    closeChooseChequeModal() {
      $("#ChooseChequeModal").modal("hide");
    },
    ChooseChequeModal2() {
      this.formChooseCheque2.montant = this.selectedResterAPayer.montant;
      this.formChooseCheque2.date = moment().format('YYYY-MM-DD');
      this.formChooseCheque2.cheque = null;
      $("#ChooseChequeModal2").modal("show");
    },
    closeChooseChequeModal2() {
      $("#ChooseChequeModal2").modal("hide");
    },
    AddVirementModal() {
      this.formAddVirement.montant = '';
      this.formAddVirement.date = moment().format('YYYY-MM-DD');
      this.formAddVirement.banque = null;
      $("#AddVirementModal").modal("show");
    },
    closeAddVirementModal() {
      $("#AddVirementModal").modal("hide");
    },
    AddVirementModal2() {
      this.formAddVirement2.montant = this.selectedResterAPayer.montant;
      this.formAddVirement2.date = moment().format('YYYY-MM-DD');
      this.formAddVirement2.banque = null;
      $("#AddVirementModal2").modal("show");
    },
    closeAddVirementModal2() {
      $("#AddVirementModal2").modal("hide");
    },
    ClearModal(){},
    ///////////////////////////////////////////////////
   closeModalInscription(){ 
      $("#InscriptionModal").modal("hide");
    },
    closeModalNewInscription(){ 
      $("#NewInscriptionModal").modal("hide");
    },
    Inscription(){
      this.Groupes = [];
      $("#InscriptionModal").modal("hide");
    },
  },
};
</script>

<style src="@vueform/multiselect/themes/default.css"></style>
<style lang="scss">
@import "../assets/assets/vendor_components/datatable/datatables.min.css";
.show {
  display: block !important;
}
.scrollable{
    overflow: auto;
    max-height: 220px;
}
</style>
