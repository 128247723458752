<template>
  <span>
    <div class="m-5">
      <div class="row">
        <!-- Main content -->
        <section class="content">
          <div class="row">
            <div class="col-12">
              <div class="box">
                <div class="box-header with-border">
                  <h4 class="box-title">Factures</h4>
                  <br>
                  <div v-if="role==1||role==2">
                    <h5 class="box-title text-danger">Total facturé : {{total.toFixed(2)}}</h5><br>
                    <h5 class="box-title text-danger">Total recouvré : {{totalRecouvre.toFixed(2)}}</h5><br>
                    <h5 class="box-title text-danger">Total Remise : {{totalRemise.toFixed(2)}}</h5><br>
                    <h5 class="box-title text-danger">Total du reste à recouvrer : {{totalResteAPayer.toFixed(2)}}</h5><br>
                    <h5 class="box-title text-danger">Total abandonné : {{totalAbondonne.toFixed(2)}}</h5><br>
                    <h5 class="box-title text-danger">Total paiement espèces : {{totalE.toFixed(2)}}</h5><br>
                    <h5 class="box-title text-danger">Total paiement chèques : {{totalCh.toFixed(2)}}</h5><br>
                    <h5 class="box-title text-danger">Total paiement carte banquaire : {{totalCr.toFixed(2)}}</h5><br>
                    <h5 class="box-title text-danger">Total paiement virement banquaire : {{totalVr.toFixed(2)}}</h5><br>
                  </div>
                </div>
                <vue-loaders-ball-beat
                  v-if="loader"
                  color="red"
                  scale="1.5"
                  style="margin: 50px auto;"
                ></vue-loaders-ball-beat>
                <div :style="loader?'display:none':''" class="box-body p-15">
                  <label class="form-label">Filter:</label>
                  <div style="border:1px dashed black;padding:10px;margin: 10 20px">
                    <div class="row" style="align-items:center">
                      <div class="col-6">
                        <div class="form-group">
                          <label class="form-label">Categorie</label>
                          <select class="form-select" v-model="filter.cat">
                            <option :value="null">Non spécifié </option> 
                            <option
                            :value="m['idcategorie']"
                              v-for="m in Categories"
                              :key="m['idcategorie']"
                              >{{m.designation}}</option> 
                          </select>
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="form-group">
                          <label class="form-label">Creance</label>
                          <select class="form-select" v-model="filter.creance">
                            <option :value="null">Non spécifié </option> 
                            <option :value="0">Acquise </option> 
                            <option :value="1">Abandonnée </option> 
                          </select>
                        </div>
                      </div>
                    </div>
                    <div class="row" style="align-items:center">
                      <div class="col-6">
                        <div class="form-group">
                          <label class="form-label">Etat</label>
                          <select class="form-select" v-model="filter.etat">
                            <option :value="null">Non spécifié </option> 
                            <option :value="1">pas tout payer </option> 
                            <option :value="2">Rien payer </option> 
                            <option :value="3">tout payer </option> 
                            <option :value="4">Sans échéance </option> 
                            <option :value="5">Échu </option> 
                          </select>
                        </div>
                      </div>
                    </div>
                    <div style="text-align: end;">
                      <button @click="filtrer" class="waves-effect  btn mb-5 " style="background-color:rgb(13, 201, 13);color:white;margin:0 5px">
                        Filtrer
                      </button>
                      <button @click="reinitialiser" class="waves-effect btn mb-5 " style="background-color:red;color:white;margin:0 5px">
                        Réinitialiser
                      </button>
                    </div>
                  </div>
                  <div style="display: flex;width: fit-content;padding: 10px;">
                    <div style="display:flex;align-items:center;margin:0 10px;">
                      <div style="width:30px;height:30px;border-radius: 50%;margin: auto;background:blue;margin: 0 5px"></div>
                      <strong style="color:blue">pas tout payer</strong>
                    </div>
                    <div style="display:flex;align-items:center;margin:0 10px;">
                      <div style="width:30px;height:30px;border-radius: 50%;margin: auto;background:red;margin: 0 5px"></div>
                      <strong style="color:red">Rien payer</strong>
                    </div>
                    <div style="display:flex;align-items:center;margin:0 10px;">
                      <div style="width:30px;height:30px;border-radius: 50%;margin: auto;background:#0fe90f;margin: 0 5px"></div>
                      <strong style="color:#0fe90f">tout payer</strong>
                    </div>
                    <div style="display:flex;align-items:center;margin:0 10px;">
                      <div style="width:30px;height:30px;border-radius: 50%;margin: auto;background:yellow;margin: 0 5px"></div>
                      <strong style="color:yellow">Sans échéance</strong>
                    </div>
                    <div style="display:flex;align-items:center;margin:0 10px;">
                      <div style="width:30px;height:30px;border-radius: 50%;margin: auto;background:orange;margin: 0 5px"></div>
                      <strong style="color:orange">Échu</strong>
                    </div>
                  </div>
                  <div class="table-responsive">
                    <div
                      id="example_wrapper"
                      class="dataTables_wrapper container-fluid dt-bootstrap4"
                    >
                      <table
                        id="factures"
                        class="table table-bordered table-striped dataTable"
                        data-page-size="10"
                      >
                        <thead>
                          <tr>
                            <th>id</th>
                            <th>date</th>
                            <th>Client</th>
                            <th>Téléphone</th>
                            <th>Montant facturé</th>
                            <th>remise</th>
                            <th>Paiements</th>
                            <th>Reste à recouvrer</th>
                            <th>Créance</th>
                            <th>Etat</th>
                          </tr>
                        </thead>
                        <tbody></tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <!--Fin main content -->
 

        <!-- modal Supprimer facture -->
        <div
          class="modal fade"
          id="DeleteModal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Supprimer facture</h5>
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  @click="closeModalDelete"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <h4>Voulez-vous vraiment supprimer cette facture?</h4>
                <div class="row">
                 <div class="col-12">
                  <div>
                    <label class="form-label">idfacture</label>
                    {{formD.idfacture}}
                  </div>
                </div>  
                </div>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-danger" data-dismiss="modal" @click="closeModalDelete">
                  Non
                </button>
                <button type="button" class="btn btn-primary float-end" id="deletefacture" @click="Delete">
                  Oui
                </button>
              </div>
            </div>
          </div>
        </div>
        <!-- /.fin modal Supprimer facture -->

        <!-- modal creance acquise facture -->
        <div
          class="modal fade"
          id="CreanceModal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Créance Acquise</h5>
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  @click="closeModalCreance"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <h4 v-if="!formC.creance">Voulez-vous vraiment Abondonnée le reste à recouvrer de cette facture?</h4>
                <h4 v-if="formC.creance">Voulez-vous vraiment Annuler l'Abandonnement du reste à recouvrer de cette facture?</h4>
                <div class="row">
                 <div class="col-12">
                  <div>
                    <label class="form-label">idfacture</label>
                    {{formC.idfacture}}
                  </div>
                </div>  
                </div>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-danger" data-dismiss="modal" @click="closeModalCreance">
                  Non
                </button>
                <button type="button" class="btn btn-primary float-end" @click="Creance">
                  Oui
                </button>
              </div>
            </div>
          </div>
        </div>
        <!-- /.fin modal creance acquise facture -->
        <div v-if="openFac">
          <Facture :FI="formI" />
        </div>
      </div>
    </div>
  </span>
</template>

<script>  
import { useVuelidate } from '@vuelidate/core'
import { required, } from '@vuelidate/validators'
import "vue-loaders/dist/vue-loaders.css";
import VueLoadersBallBeat from "vue-loaders/dist/loaders/ball-beat";
import FactureApi from "../../../api/facture";
import Facture from "../../../components/facture.vue";
import catApi from "../../../api/categorie";
import moment from 'moment';

let $ = window.$;
export default { 
  setup () {
    return {  v: useVuelidate() }
  },
  components: {
    [VueLoadersBallBeat.component.name]: VueLoadersBallBeat.component,
    Facture,
  },
  data() {
    return { 
      loader:false,
      Factures:[],  
      Categories:[],
      formD:{ 
        idfacture:'', 
      },
      formC:{ 
        idfacture:'', 
        creance:false, 
      },
      url:null,
      formI:{ 
        nom:'',
        prenom:'', 
        idclient:'' ,
        idfacture:0,
        CategorieId:null,
      },
      filter:{
        creance:null,
        cat:null,
        etat:null,
      },
      openFac:false,
      total:0,
      totalRemise:0,
      totalRecouvre:0,
      totalAbondonne:0,
      totalResteAPayer:0,
      totalE:0,
      totalCh:0,
      totalCr:0,
      totalVr:0,
      role:localStorage.getItem("role"),
    };
  },
  validations () {
    return { 
      formA:{
        idClient:{ required },
        remise:{ required },
        motif:{ required },
        creance:{ required },
      },
      formU:{ 
        idClient:{ required },
        remise:{ required },
        motif:{ required },
        creance:{ required },
      },
    }
  },
  mounted() {
    this.datatable();
    catApi.getAllCategories((r) => {
      if(r!=null){
        if(r.success==true){ 
          this.Categories = r.data;
        }
      }
    });
  },
  methods: {
    OpenFacture(d){
      this.openFac = false;
      setTimeout( ()=> {
        this.formI.nom = d.nom;
        this.formI.prenom = d.prenom;
        this.formI.idclient = d.idclient;
        this.formI.idfacture = d.id;
        this.formI.CategorieId = d.categorie_idcategorie;
        this.openFac = true;
      },500)
    },
    async loadData() {
      this.loader = true;
      await FactureApi.getAllData((r) => {
        if(r!=null){
          if(r.success==true){
            this.Factures = []; 
            this.total=0;
            this.totalRemise=0;
            this.totalRecouvre=0;
            this.totalAbondonne=0;
            this.totalResteAPayer=0;
            this.totalE=0;
            this.totalCh=0;
            this.totalCr=0;
            for(const f of r.data){
              let etat = -1;
              if( parseFloat(f.rester_recouvrer) > 0 && parseFloat(f.paiement) > 0 )
                etat = 1;
              if( parseFloat(f.rester_recouvrer) > 0 && parseFloat(f.paiement) <= 0 )
                etat = 2;
              if( parseFloat(f.rester_recouvrer) == 0 || f.organisme.toUpperCase() == "GRATUIT".toUpperCase())
                etat = 3;
              if(f.resteAPayer.filter(v=> v.date == null).length > 0)
                etat = 4;
              if( f.resteAPayer.filter(v=> moment(v.date) < moment()).length > 0)
                etat = 5;
              if((this.filter.cat == f.categorie_idcategorie || this.filter.cat == null)
                && (this.filter.creance == f.creance || this.filter.creance == null) 
                && (this.filter.etat == etat || this.filter.etat == null) 
                )
              {
                this.total+= f.montant_facture?parseFloat(f.montant_facture):0;
                this.totalRemise += f.remise?parseFloat(f.remise):0;
                this.totalRecouvre+= f.paiement?parseFloat(f.paiement):0;
                this.totalResteAPayer+= f.rester_recouvrer?parseFloat(f.rester_recouvrer):0;
                this.totalE+=f.paiementE?parseFloat(f.paiementE):0;
                this.totalCh+=f.paiementCh?parseFloat(f.paiementCh):0;
                this.totalCr+=f.paiementCr?parseFloat(f.paiementCr):0;
                this.totalVr+=f.paiementVr?parseFloat(f.paiementVr):0;
                if(f.creance==1)
                  this.totalAbondonne+= f.rester_recouvrer?parseFloat(f.rester_recouvrer):0;
                this.Factures.push({
                  id:f.id,
                  date:f.date,
                  client:f.client,
                  montant_facture:f.montant_facture,
                  remise:f.remise,
                  paiement:f.paiement,
                  rester_recouvrer:f.rester_recouvrer,
                  creance:f.creance,
                  creanceTxt:f.creance==0?"Acquise":"Abandonnée",
                  nom:f.nom,
                  prenom:f.prenom,
                  idclient:f.idclient,
                  categorie_idcategorie:f.categorie_idcategorie,
                  organisme:f.organisme,
                  telephone:f.telephone,
                  etat:etat,
                });
              }
            }
            var t = $("#factures").DataTable();
            t.clear().rows.add(this.Factures).draw();
            this.loader = false;
          }
          else{
            this.loader = false;
          }
        }
        else{
          this.loader = false;
        }
      });
    },
    
    filtrer(){
      this.loadData();
    },
    reinitialiser(){
      this.filter.cat = null;
      this.filter.creance = null;
      this.filter.etat = null;
      this.loadData();
    },
    datatable() {
      this.loadData();
      $(function () {
        "use strict";
        $("#factures").DataTable({
          paging: true,
          lengthChange: true,
          searching: true,
          ordering: true,
          info: true,
          autoWidth: false,
          order:[[0,"desc"]],
          data: this.Factures,
          columns: [
            {data: "id"},
            {data: "date"},
            {data:"client"},
            {data:"telephone"},
            {data:"montant_facture"},
            {data:"remise"},
            {data:"paiement"},
            {data:"rester_recouvrer"},
            {data:"creanceTxt"},
            {data:"rester_recouvrer",
              render:function(data,type,row,meta){
                const reste = parseFloat(data);
                const paiement = parseFloat(row.paiement);
                let result = "";
                if( reste > 0 && paiement > 0 )
                  result = '<div style="width:30px;height:30px;border-radius: 50%;margin: auto;background:blue;"></div>';
                if( reste > 0 && paiement <= 0 )
                  result = '<div style="width:30px;height:30px;border-radius: 50%;margin: auto;background:red;"></div>';
                if( reste <= 0 || row.organisme.toUpperCase() == "GRATUIT".toUpperCase())
                  result = '<div style="width:30px;height:30px;border-radius: 50%;margin: auto;background:#0fe90f;"></div>';
                if( row.etat == 4)
                  result = '<div style="width:30px;height:30px;border-radius: 50%;margin: auto;background:yellow;"></div>';
                if( row.etat == 5)
                  result = '<div style="width:30px;height:30px;border-radius: 50%;margin: auto;background:orange;"></div>';
                return result;
              }
            },
            {
              defaultContent:
                '<button class="waves-effect waves-circle btn btn-social-icon btn-circle btn-info btnShow"> <i title="Afficher" style="cursor:pointer;" class="ti-eye  " aria-hidden="true"></i></button> <button class="waves-effect waves-circle btn btn-social-icon btn-circle btn-danger btnDelete"><i title="Supprimer" style="cursor:pointer;" class="ti-trash " aria-hidden="true"></i></button> <button class="waves-effect waves-circle btn btn-social-icon btn-circle btn-danger btnAcquise"><i title="creance acquise" style="cursor:pointer;" class="ti-money " aria-hidden="true"></i></button><button class="waves-effect waves-circle btn btn-social-icon btn-circle btn-info btnShowClient"><i title="creance acquise" style="cursor:pointer;" class="ti-user " aria-hidden="true"></i></button></div>',
            },
          ],
        });
      });
      const thisVue = this; 

      //for delete
      $("#factures tbody ").on("click", ".btnDelete", function () {
        var t = $("#factures").DataTable();
        var data = t.row($(this).parents("tr")).data();
        $("#DeleteModal").modal();
        thisVue.DeleteModal()
        thisVue.formD.idfacture = data["id"];
      });

      //for show
      $("#factures tbody ").on("click", ".btnShow", function () {
        var t = $("#factures").DataTable();
        var data = t.row($(this).parents("tr")).data();
        thisVue.OpenFacture(data);
      });

      //for show client
      $("#factures tbody ").on("click", ".btnShowClient", function () {
        var t = $("#factures").DataTable();
        var data = t.row($(this).parents("tr")).data();
        const id = data.idclient;
        window.open('/admin/clients/'+id, '_blank');
      });

      //for acquise
      $("#factures tbody ").on("click", ".btnAcquise", function () {
        var t = $("#factures").DataTable();
        var data = t.row($(this).parents("tr")).data();
        thisVue.formC.idfacture = data["id"];
        thisVue.formC.creance = data["creance"]==0?false:true;
        thisVue.CreanceModal()
      });
    },  
    DeleteModal() {
      $("#DeleteModal").modal("show");
    },
    closeModalDelete() {
      $("#DeleteModal").modal("hide");
    },
    CreanceModal() {
      $("#CreanceModal").modal("show");
    },
    closeModalCreance() {
      $("#CreanceModal").modal("hide");
    },
    ClearModal(){},
    Delete(){
      FactureApi.delete({
        id:this.formD.idfacture, 
       },(r) => {
         if(r!=null)
         {
           if(r.success==true){
            this.loadData();
            this.ClearModal();
            this.closeModalDelete()
           }
         }
      });
    },
    Creance(){
      FactureApi.creance({
        id:this.formC.idfacture, 
        creance:!this.formC.creance, 
       },(r) => {
         if(r!=null)
         {
           if(r.success==true){
            this.loadData();
            this.ClearModal();
            this.closeModalCreance()
           }
         }
      });
    },
  },
};
</script>

<style lang="scss">
.show {
  display: block !important;
}
.error-msg {
  color: red;
}

.error-border {
  border: 1px solid red;
  border-radius: 8px;
}
</style>
