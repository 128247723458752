import myConfig from './myConfig';
import axios from 'axios';

const token = localStorage.getItem('token');
export default {
    getAll(callback){

        axios.get(myConfig.api_url+`/api/v1/allProfs?api_token=${token}`)
        .then((response) => {
            if (response.status == 200) {
                callback(response.data);
            }
            else{
                callback(null);
            }
        })
        .catch(() => {
            callback(null);
        });

    },

    getAllSeances(callback){

        axios.get(myConfig.api_url+`/api/v1/getAllSeances?api_token=${token}`)
        .then((response) => {
            if (response.status == 200) {
                callback(response.data);
            }
            else{
                callback(null);
            }
        })
        .catch(() => {
            callback(null);
        });

    },

    ReglerSeance(args,callback){

        axios.post(myConfig.api_url+`/api/v1/ReglerSeance?api_token=${token}`,args)
        .then((response) => {
            if (response.status == 200) {
                callback(response.data);
            }
            else{
                callback(null);
            }
        })
        .catch(() => {
            callback(null);
        });

    },

    AnnulerReglementSeance(args,callback){
        
        axios.post(myConfig.api_url+`/api/v1/AnnulerReglementSeance?api_token=${token}`,args)
        .then((response) => {
            if (response.status == 200) {
                callback(response.data);
            }
            else{
                callback(null);
            }
        })
        .catch(() => {
            callback(null);
        });

    },

    getProfTarifsByIdProf(args,callback){
        
        axios.post(myConfig.api_url+`/api/v1/getProfTarifsByIdProf?api_token=${token}`,args)
        .then((response) => {
            if (response.status == 200) {
                callback(response.data);
            }
            else{
                callback(null);
            }
        })
        .catch(() => {
            callback(null);
        });

    },

    addProfTarifs(args,callback){
        //montant:?, prof_idprof:?, matiere_idmatiere:?,
        axios.post(myConfig.api_url+`/api/v1/createProfTarifs?api_token=${token}`,args)
        .then((response) => {
            if (response.status == 200) {
                callback(response.data);
            }
            else{
                callback(null);
            }
        })
        .catch(() => {
            callback(null);
        });

    },

    updateProfTarifs(args,callback){

        //montant:?, prof_idprof:?, matiere_idmatiere:?, id:?
        axios.post(myConfig.api_url+`/api/v1/updateProfTarifs?api_token=${token}`,args)
        .then((response) => {
            if (response.status == 200) {
                callback(response.data);
            }
            else{
                callback(null);
            }
        })
        .catch(() => {
            callback(null);
        });

    },

    deleteProfTarifs(args,callback){
        //id:? ,
        axios.post(myConfig.api_url+`/api/v1/deleteProfTarifs?api_token=${token}`,args)
        .then((response) => {
            if (response.status == 200) {
                callback(response.data);
            }
            else{
                callback(null);
            }
        })
        .catch(() => {
            callback(null);
        });

    },

    add(args,callback){

        //nom:?, prenom:?, telephone:?, email:?, mot_de_passe:?,
        axios.post(myConfig.api_url+`/api/v1/addProf?api_token=${token}`,args)
        .then((response) => {
            if (response.status == 200) {
                callback(response.data);
            }
            else{
                callback(null);
            }
        })
        .catch(() => {
            callback(null);
        });

    },

    update(args,callback){
        // id:?, nom:?, prenom:?, telephone:?, email:?, mot_de_passe:?,
        axios.post(myConfig.api_url+`/api/v1/updateProf?api_token=${token}`,args)
        .then((response) => {
            if (response.status == 200) {
                callback(response.data);
            }
            else{
                callback(null);
            }
        })
        .catch(() => {
            callback(null);
        });

    },

    delete(args,callback){
        //id:? ,
        axios.post(myConfig.api_url+`/api/v1/deleteProf?api_token=${token}`,args)
        .then((response) => {
            if (response.status == 200) {
                callback(response.data);
            }
            else{
                callback(null);
            }
        })
        .catch(() => {
            callback(null);
        });

    },

}