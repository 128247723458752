import myConfig from './myConfig';
import axios from 'axios';

const token = localStorage.getItem('token');
export default { 
    getAll(callback){

    axios.get(myConfig.api_url+`/api/v1/allFactures?api_token=${token}`)
        .then((response) => {
            if (response.status == 200) {
                callback(response.data);
            }
            else{
                callback(null);
            }
        })
        .catch(() => {
            callback(null);
        });

    },

    getAllData(callback){

        axios.get(myConfig.api_url+`/api/v1/getAllDataFactures?api_token=${token}&center=${localStorage.getItem('centre')}`)
            .then((response) => {
                if (response.status == 200) {
                    callback(response.data);
                }
                else{
                    callback(null);
                }
            })
            .catch(() => {
                callback(null);
            });
    
        },

    getOneAllDataFacture(args,callback){

        axios.post(myConfig.api_url+`/api/v1/getOneAllDataFacture?api_token=${token}`,args)
            .then((response) => {
                if (response.status == 200) {
                    callback(response.data);
                }
                else{
                    callback(null);
                }
            })
            .catch(() => {
                callback(null);
            });
    
        },

        getIdFactureByIdClient(args,callback){

        axios.post(myConfig.api_url+`/api/v1/getIdFactureByIdClient?api_token=${token}`,args)
            .then((response) => {
                if (response.status == 200) {
                    callback(response.data);
                }
                else{
                    callback(null);
                }
            })
            .catch(() => {
                callback(null);
            });
    
        },

    add(args,callback){

        //designation:? , debut:?, fin:?,
        axios.post(myConfig.api_url+`/api/v1/addFacture?api_token=${token}`,args)
        .then((response) => {
            if (response.status == 200) {
                callback(response.data);
            }
            else{
                callback(null);
            }
        })
        .catch(() => {
            callback(null);
        });

    },

    createPaiementCheque(args,callback){

        
        axios.post(myConfig.api_url+`/api/v1/createPaiementCheque?api_token=${token}`,args)
        .then((response) => {
            if (response.status == 200) {
                callback(response.data);
            }
            else{
                callback(null);
            }
        })
        .catch(() => {
            callback(null);
        });

    },

    deletePaiementCheque(args,callback){

        
        axios.post(myConfig.api_url+`/api/v1/deletePaiementCheque?api_token=${token}`,args)
        .then((response) => {
            if (response.status == 200) {
                callback(response.data);
            }
            else{
                callback(null);
            }
        })
        .catch(() => {
            callback(null);
        });

    },

    createPaiementCarte(args,callback){

        
        axios.post(myConfig.api_url+`/api/v1/createPaiementCarte?api_token=${token}`,args)
        .then((response) => {
            if (response.status == 200) {
                callback(response.data);
            }
            else{
                callback(null);
            }
        })
        .catch(() => {
            callback(null);
        });

    },

    deletePaiementCarte(args,callback){

        
        axios.post(myConfig.api_url+`/api/v1/deletePaiementCarte?api_token=${token}`,args)
        .then((response) => {
            if (response.status == 200) {
                callback(response.data);
            }
            else{
                callback(null);
            }
        })
        .catch(() => {
            callback(null);
        });

    },

    createPaiementVirement(args,callback){

        
        axios.post(myConfig.api_url+`/api/v1/createPaiementVirement?api_token=${token}`,args)
        .then((response) => {
            if (response.status == 200) {
                callback(response.data);
            }
            else{
                callback(null);
            }
        })
        .catch(() => {
            callback(null);
        });

    },

    deletePaiementVirement(args,callback){

        
        axios.post(myConfig.api_url+`/api/v1/deletePaiementVirement?api_token=${token}`,args)
        .then((response) => {
            if (response.status == 200) {
                callback(response.data);
            }
            else{
                callback(null);
            }
        })
        .catch(() => {
            callback(null);
        });

    },

    createPaiementEspece(args,callback){

        
        axios.post(myConfig.api_url+`/api/v1/createPaiementEspece?api_token=${token}`,args)
        .then((response) => {
            if (response.status == 200) {
                callback(response.data);
            }
            else{
                callback(null);
            }
        })
        .catch(() => {
            callback(null);
        });

    },

    deletePaiementEspece(args,callback){

        
        axios.post(myConfig.api_url+`/api/v1/deletePaiementEspece?api_token=${token}`,args)
        .then((response) => {
            if (response.status == 200) {
                callback(response.data);
            }
            else{
                callback(null);
            }
        })
        .catch(() => {
            callback(null);
        });

    },


    createResteAPayer(args,callback){

        
        axios.post(myConfig.api_url+`/api/v1/createResteAPayer?api_token=${token}`,args)
        .then((response) => {
            if (response.status == 200) {
                callback(response.data);
            }
            else{
                callback(null);
            }
        })
        .catch(() => {
            callback(null);
        });

    },

    updateResteAPayer(args,callback){

        
        axios.post(myConfig.api_url+`/api/v1/updateResteAPayer?api_token=${token}`,args)
        .then((response) => {
            if (response.status == 200) {
                callback(response.data);
            }
            else{
                callback(null);
            }
        })
        .catch(() => {
            callback(null);
        });

    },

    deleteResteAPayer(args,callback){

        
        axios.post(myConfig.api_url+`/api/v1/deleteResteAPayer?api_token=${token}`,args)
        .then((response) => {
            if (response.status == 200) {
                callback(response.data);
            }
            else{
                callback(null);
            }
        })
        .catch(() => {
            callback(null);
        });

    },

   

    update(args,callback){
        //id:?, designation:?, debut:?, fin:?,
        axios.post(myConfig.api_url+`/api/v1/updateFacture?api_token=${token}`,args)
        .then((response) => {
            if (response.status == 200) {
                callback(response.data);
            }
            else{
                callback(null);
            }
        })
        .catch(() => {
            callback(null);
        });

    },

    delete(args,callback){
        //id:? ,
        axios.post(myConfig.api_url+`/api/v1/deleteFacture?api_token=${token}`,args)
        .then((response) => {
            if (response.status == 200) {
                callback(response.data);
            }
            else{
                callback(null);
            }
        })
        .catch(() => {
            callback(null);
        });

    },

    creance(args,callback){
        //id:? , creance:?
        axios.post(myConfig.api_url+`/api/v1/creance?api_token=${token}`,args)
        .then((response) => {
            if (response.status == 200) {
                callback(response.data);
            }
            else{
                callback(null);
            }
        })
        .catch(() => {
            callback(null);
        });

    },

}